import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import KuroLink from '../components/common/KuroLink'
import ResponseBox from '../components/ResponseBox'
import { getVendors } from '../actions/admin'
import note from '../assets/img/button.png'
import '../styles/table.css'
import Select from 'react-select'


const CreateInwardInvoice = ({ user: { token, userDetails }, admin: { vendors }, getVendors }) => {

    let history = useHistory()
    const definvoice = { 'create_po': false, 'po_no': '', 'vendor': '', 'gstin': '', 'invoice_no': '', 'invoice_date': '', 'invoice_type': null, 'itc_received': 'NA', 'ittcs': 0, 'totalprice': 0, 'cgst': 0, 'sgst': 0, 'igst': 0, 'due_date': '', 'pay_status': 'Payment Pending', 'settled': 'No', 'desc': '', "tags": [] }
    const vendorOptions = vendors ? vendors.map(item => ({ value: item.vendor_code, label: item.name })) : [];
    const [invoicedata, setinvoicedata] = useState(definvoice)
    const [podata, setpodata] = useState([])
    const [submitted, setsubmitted] = useState(false)
    const [resstatus, setresstatus] = useState("")
    const [tagselectall, settagselectall] = useState(false)
    const selectAllRef = useRef(null);
    const [poOptions, setPoOptions] = useState([])

    const invoicetypes = [
        "Trading Stock",
        "Fixed Assets",
        "Expenses - Food & Entertainment",
        "Expenses - Utilities",
        "Expenses - Office Supplies",
        "Expenses - Logistics",
        "Expenses - Financial Services",
        "Expenses - Services",
        "Govt Fees and Taxes",
        "Govt Penalties",
    ]

    const tagOptionsHandler = (type) => {
        const tagOptions = {
            "Expenses - Utilities": [ "Electricity Bill", "Water Bill", "Internet Service" ],
            "Expenses - Food & Entertainment": [ "Food", "Beverages" ],
            "Expenses - Office Supplies": [ "Stationary", "Cleaning" ],
            "Expenses - Financial Services": [ "Payment Gateway", "POS Machine" ],
            "Expenses - Services": [ "TP Commission", "Advertisement", "Cloud Services" ],
        };
        return tagOptions[type] || [];
    }

    useEffect(() => {
        getVendors()
        const access = userDetails.accesslevel.inward_invoices;
        if (access === "NA" || access === "read") {
            history.push('/unauthorized')
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/purchaseorders?limit=100', config)
            .then((res) => {
                setpodata(res.data)
            })
    }, [getVendors])

    useEffect(() => {
        if (selectAllRef.current) {
            if (invoicedata.tags.length === 0) {
                selectAllRef.current.indeterminate = false;
                selectAllRef.current.checked = false;
            } else if (invoicedata.tags.length === tagOptionsHandler(invoicedata.invoice_type).length) {
                selectAllRef.current.indeterminate = false;
                selectAllRef.current.checked = true;
            } else {
                selectAllRef.current.indeterminate = true;
            }
        }
    }, [invoicedata.tags, invoicedata.invoice_type]);

    useEffect(() => {
        let poOps = podata.map(item => ({ value: item.po_no, label: item.po_no }));
        setPoOptions(poOps)
    }, [podata])

    const toggleselectall = () => {
        let tempinvoice = { ...invoicedata };
        let selectedTags = []
        if (tagselectall === true) {
            selectedTags = []
            settagselectall(false)
        } else {
            tagOptionsHandler(tempinvoice.invoice_type).map((tag) => {
                selectedTags.push(tag)
            })
            settagselectall(true)
        }
        tempinvoice.tags = selectedTags
        setinvoicedata(tempinvoice)
    }

    const updateinvoice = (key, e) => {
        let tempinvoice = { ...invoicedata }
        if (key === 'po_no') {
            tempinvoice[key] = e.value
            if (e.value === '') {
                tempinvoice.vendor = ''
            } else {
                let poDetails = podata.filter(po => po.po_no === e.value)[0]
                let pay_status = vendors.filter(ven => ven.vendor_code === poDetails.vendor)[0].payment_type
                tempinvoice.vendor = poDetails.vendor
                tempinvoice.invoice_type = poDetails.type
                tempinvoice.tags = poDetails.tags
                tempinvoice.pay_status = pay_status === "Post Paid" ? "Payment Pending" : pay_status
                tempinvoice.totalprice = poDetails.total_amount
            }
        } else if (key === 'vendor') {
            tempinvoice[key] = e.value
            let selectedVendor = vendors.find(vendor => vendor.vendor_code === e.value)
            tempinvoice.invoice_type = selectedVendor.type
            tempinvoice.tags = selectedVendor.tags
            tempinvoice.pay_status = selectedVendor.payment_type === "Post Paid" ? "Payment Pending" : selectedVendor.payment_type
        } else if (key === 'gstin') {
            tempinvoice[key] = e.target.value
            tempinvoice.cgst = 0
            tempinvoice.sgst = 0
            tempinvoice.igst = 0
            tempinvoice.totalprice = 0
            if (tempinvoice.gstin !== "" && tempinvoice.invoice_type !== "Expenses - Food & Entertainment") {
                tempinvoice.itc_received = 'No'
            }
        } else {
            tempinvoice[key] = e.target.value
        }
        setinvoicedata(tempinvoice)
    }

    const updateinvoicedate = (key, date) => {
        let tempinvoice = { ...invoicedata }
        
        if (key === "invoice_date") {
            let dueDate = new Date(date);
            let credit = vendors.find(vendor => vendor.vendor_code === tempinvoice['vendor']).credit;
            dueDate.setDate(dueDate.getDate() + credit);
            tempinvoice['due_date'] = dueDate;
        }
        tempinvoice[key] = date;
        setinvoicedata(tempinvoice);
    };
    

    const updateTag = (tag, index) => {
        let selectedTags = invoicedata.tags
        if (selectedTags.includes(tag)) {
            selectedTags.splice(index, 1)
        } else {
            selectedTags.push(tag)
        }
        setinvoicedata((prev) => ({
            ...prev,
            tags: [...selectedTags],
        }));
    };

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const getpono = (key) => {
        return { value: invoicedata[key], label: invoicedata[key] }
    }

    const getselectedvendor = (key) => {
        return ({ value: invoicedata[key], label: vendors.filter((item) => item.vendor_code === invoicedata[key]) })[0]
    }

    const updateprice = (key, e) => {
        let tempinvoice = { ...invoicedata }
        tempinvoice[key] = e.target.value
        let reg = /^[0-9.]*$/
        if (!reg.test(tempinvoice[key])) {
            return tempinvoice[key]
        }
        if (key === "totalprice" || key === "ittcs") {
            if (tempinvoice.gstin === "") {
                tempinvoice.cgst = 0
                tempinvoice.sgst = 0
                tempinvoice.igst = 0
            } else if (tempinvoice.gstin.startsWith("36")) {
                tempinvoice.cgst = round("round", ((parseFloat(tempinvoice.totalprice) - parseFloat(tempinvoice.ittcs)) / 1.18) * 0.09, 2)
                tempinvoice.sgst = round("round", ((parseFloat(tempinvoice.totalprice) - parseFloat(tempinvoice.ittcs)) / 1.18) * 0.09, 2)
                tempinvoice.igst = 0
            } else {
                tempinvoice.cgst = 0
                tempinvoice.sgst = 0
                tempinvoice.igst = round("round", ((parseFloat(tempinvoice.totalprice) - parseFloat(tempinvoice.ittcs)) / 1.18) * 0.18, 2)
            }
        } else if (key === "cgst") {
            tempinvoice.sgst = e.target.value
        } else if (key === "igst") {
            tempinvoice.cgst = 0
            tempinvoice.sgst = 0
        }
        setinvoicedata(tempinvoice)
    }

    const updatepostatus = (value) => {
        let tempinvoice = { ...invoicedata }
        tempinvoice.create_po = value
        tempinvoice.po_no = ""
        tempinvoice.vendor = ""
        setinvoicedata(tempinvoice)
    }

    const submitHandler = () => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let invoice = invoicedata

        for (let key of ["invoice_no", "desc"]) {
            invoice[key] = invoice[key].trim()
        }

        for (let key of ["totalprice", "ittcs", "cgst", "sgst", "igst"]) {
            if (invoice[key] === "" || invoice[key] === null) {
                invoice[key] = 0
            } else {
                invoice[key] = parseFloat(invoice[key])
            }
        }

        if (invoice.invoice_no !== "" && invoice.vendor !== "" && invoice.invoice_date !== "" && invoice.desc!=="") {
            let invoices = []
            invoices.push(invoice)
            axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardinvoices', invoices, config)
                .then((res) => {
                    setresstatus(res.status)
                })
            setsubmitted(true)
        }
    }

    const resetHandler = () => {
        setinvoicedata(definvoice)
    }

    const okHandler = () => {
        setresstatus("")
        setsubmitted(false)
        history.push('/inward-invoices')
    }

    return (
        <div className="kuro_inventory entry txt-light">
            <h2 className="txt-light-grey">Create Purchase Invoice</h2>
            <div className="note"><p>Instructions </p>
                <img src={note} alt='instruction' className='note_img' />:
                <span className="note_text">lorem text</span>
            </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            {submitted ? (
                <div>
                    {resstatus === 200 ? (
                        <ResponseBox msg="Invoice has been Submitted Succesfully." okhandler={okHandler} />
                    ) : (
                        <ResponseBox msg="Oops something gone wrong!" okhandler={okHandler} />
                    )}
                </div>
            ) : (
                <>
                    {invoicedata !== null &&
                        <table className="home border" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <th>Create PO</th>
                                    <td><input type="checkbox" onChange={() => updatepostatus(!invoicedata.create_po)} checked={invoicedata.create_po} /></td>
                                </tr>
                                <tr>
                                    <th>PO No.</th>
                                    <td>
                                        {invoicedata.create_po ? (
                                            "Not Applicable"
                                        ) : (
                                            <Select
                                                classNamePrefix="kuro-search-select"
                                                options={poOptions}
                                                defaultValue={getpono("po_no")}
                                                onChange={(e) => updateinvoice("po_no", e)}
                                                placeholder="Select Model"
                                                className="react-select-container"
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Vendor</th>
                                    <td>
                                        {vendors !== null && 
                                            (invoicedata.create_po ? (
                                                <Select
                                                    classNamePrefix="kuro-search-select"
                                                    options={vendorOptions}
                                                    defaultValue={getselectedvendor("vendor")}
                                                    onChange={(e) => updateinvoice("vendor", e)}
                                                    placeholder="Select vendor"
                                                    className="react-select-container"
                                                />
                                            ) : (
                                                vendors.filter(vendor => vendor.vendor_code === invoicedata.vendor).map((vendor) =>
                                                    vendor.name
                                                )
                                            ))
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Vendor GSTIN</th>
                                    <td>
                                        <select value={invoicedata.gstin} onChange={(e) => updateinvoice("gstin", e)}>
                                            <option value="">Select Vendor GST</option>
                                            {vendors !== null && invoicedata.vendor !== "" &&
                                                vendors.filter(vendor => vendor.vendor_code === invoicedata.vendor).map((vendor, j) =>
                                                    vendor.gstdetails.map((item, k) =>
                                                        <option key={j + k} value={item.gst.gstin}>{item.gst.gstin}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice No.</th>
                                    <td>
                                        <input value={invoicedata.invoice_no} onChange={(e) => updateinvoice("invoice_no", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice Date</th>
                                    <td>
                                        <DatePicker className="large" dateFormat='dd-MMM-yyyy' selected={invoicedata.invoice_date} onChange={date => updateinvoicedate("invoice_date", date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice Total</th>
                                    <td>
                                        <input className="large" value={invoicedata.totalprice} onChange={(e) => updateprice("totalprice", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Invoice Type</th>
                                    <td>
                                        <select value={invoicedata.invoice_type} onChange={(e) => updateinvoice("invoice_type", e)}>
                                            <option value="">Select invoice type</option>
                                            {invoicetypes.map((type, index) =>
                                                    <option key={index} value={type} >{type}</option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                                {invoicedata.invoice_type && (
                                <tr>
                                    <th>Tags</th>
                                    <td>
                                        {tagOptionsHandler(invoicedata.invoice_type) && tagOptionsHandler(invoicedata.invoice_type).length > 0 ? (
                                            <>
                                            <div className="checkbox-container">
                                                    <span>
                                                        <input
                                                            type="checkbox"
                                                            ref={selectAllRef}
                                                            checked={tagselectall}
                                                            onChange={() => toggleselectall()}
                                                        />
                                                    </span>
                                                    <span>Select All</span>
                                                </div>
                                                {tagOptionsHandler(invoicedata.invoice_type).map((tag, i) => (
                                                    <div key={i} className="checkbox-container-inner">
                                                        <span>
                                                            <input
                                                                type="checkbox"
                                                                checked={invoicedata.tags.includes(tag)}
                                                                onChange={() => updateTag(tag, i)}
                                                            />
                                                        </span>
                                                        <span>{tag}</span>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <p>No Options</p>
                                        )}
                                    </td>
                                </tr>
                                )}
                                <tr>
                                    <th>ITC Received</th>
                                    <td>
                                        {invoicedata.gstin === "" ? "Not Applicable" : `${invoicedata.itc_received}`}
                                    </td>
                                </tr>
                                <tr>
                                    <th>IT TCS</th>
                                    <td><input className="large" value={invoicedata.ittcs} onChange={(e) => updateprice("ittcs", e)} /></td>
                                </tr>
                                <tr>
                                    <th>CGST/SGST</th>
                                    <td>
                                        {(invoicedata.gstin.startsWith("36")) ? (
                                            <input value={invoicedata.cgst} onChange={(e) => updateprice("cgst", e)} />
                                        ) : (
                                            invoicedata.cgst
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>IGST</th>
                                    <td>
                                        {invoicedata.gstin === "" || invoicedata.gstin.startsWith("36") ? (
                                            invoicedata.igst
                                        ) : (
                                            <input value={invoicedata.igst} onChange={(e) => updateprice("igst", e)} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Due Date</th>
                                    <td>
                                        <DatePicker className="large" dateFormat='dd-MMM-yyyy' selected={invoicedata.due_date} onChange={date => updateinvoicedate("due_date", date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Payment Status</th>
                                    <td>
                                        {invoicedata.pay_status}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Settled</th>
                                    <td>
                                    {invoicedata.settled}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td><textarea className='large' value={invoicedata.desc} onChange={(e) => updateinvoice("desc", e)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    }
                    <ul className='btns'>
                        <li>
                            <button onClick={resetHandler}>Clear All</button>
                        </li>
                        <li>
                            {(invoicedata.invoice_no !== "" && invoicedata.invoice_date !== "" && invoicedata.due_date !== "" && invoicedata.desc!=="") ? (
                                <button onClick={submitHandler}>Submit</button>
                            ) : (
                                <button style={{ background: "#87858e", color: "#000" }}>Submit</button>
                            )}
                        </li>
                        <li>
                            <KuroLink to="/inward-invoices"><button>Back</button></KuroLink>
                        </li>
                    </ul>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(CreateInwardInvoice)