import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Select from 'react-select'
import KuroLink from '../components/common/KuroLink'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

moment.tz.setDefault('Asia/Kolkata')

const TPOrderProducts = ({ match, user: { token, userDetails }, products: { compData, monitorData, accessData, extDeviceData, networkData } }) => {

    const { params: { orderId } } = match

    const [orderData, setorderData] = useState(null)
    const [updatedorder, setupdatedorder] = useState(null)
    const [compMakers, setCompMakers] = useState([])
    const [monitorMakers, setMonitorMakers] = useState([])
    const [accessMakers, setAccessMakers] = useState([])
    const [networkMakers, setNetworkMakers] = useState([])
    const [extMakers, setExtMakers] = useState([])
    const [errormsg, seterrormsg] = useState("")
    const [submitted, setsubmitted] = useState(false)

    const history = useHistory()
    const access = userDetails.accesslevel.orders
    const defbuild = {
        "title": "",
        "components": [
            {
                "collection": "components",
                "type": "cpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "mob",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "gpu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ram",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "ssd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "hdd",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "psu",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "tower",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "cooler",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "components",
                "type": "fan",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            },
            {
                "collection": "networking",
                "type": "wifi-card",
                "maker": "",
                "productid": "",
                "title": "",
                "quantity": 1
            }
        ],
        "hsncode": "",
        "tax_rate": 18,
        "buildprice": 0,
        "discount": 0,
        "totaldiscount": 0,
        "pricebgst": 0,
        "price": 0,
        "quantity": 1,
        "totalpricebgst": 0,
        "totalprice": 0
    }

    const formatorder = (order) => {
        order.builds = []
        order.products = []
        order.services = [];
        setupdatedorder(order)
    }

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + orderId, config).then(res => {
            let order = res.data[0]
            setorderData(order)
            formatorder(order)
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [orderId])


    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updatetotal = (temporder) => {
        temporder.taxes = {}
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        let subtotals = {
            "5": 0,
            "12": 0,
            "18": 0,
            "28": 0
        }
        for (let i = 0; i < temporder.builds.length; i++) {
            subtotals[temporder.builds[i].tax_rate] += temporder.builds[i].price
        }
        for (let i = 0; i < temporder.products.length; i++) {
            subtotals[temporder.products[i].tax_rate] += temporder.products[i].price
        }

        for (const [key, value] of Object.entries(subtotals)) {
            let tpbg = 0
            let trgst = 0
            let trcgst = 0
            tpbg = round("round", (100 * value) / (100 + parseInt(key)), 2)
            totalpricebgst += tpbg
            trgst = round("round", (value * parseInt(key)) / (100 + parseInt(key)), 2)
            trcgst = round("round", (value * parseInt(key)) / (2 * (100 + parseInt(key))), 2)
            if (temporder.billadd.state === "Telangana") {
                temporder.taxes[key] = trcgst
            } else {
                temporder.taxes[key] = trgst
            }
            gst += trgst
            cgst += trcgst
        }
        temporder.totalpricebgst = round("round", totalpricebgst, 2)
        temporder.gst = round("round", gst, 2)
        temporder.cgst = round("round", cgst, 2)

        temporder = updateroundoff(temporder)
        return temporder
    }

    const updateroundoff = (temporder) => {
        let roundoff = 0
        if (temporder.billadd.state === "Telangana") {
            roundoff = ((temporder.totalprice * 100) - (200 * temporder.cgst) - (100 * temporder.totalpricebgst))
        } else {
            roundoff = ((temporder.totalprice * 100) - (100 * temporder.gst) - (100 * temporder.totalpricebgst))
        }
        temporder.roundoff = round("round", Math.abs(roundoff) >= 1 ? roundoff / 100 : 0, 2)
        return temporder
    }

    useEffect(() => {
        if (access !== "edit" && access !== "write") {
            history.push("/unauthorized")
        }
    }, [])

    useEffect(() => {
        if (compData != null) {
            setCompMakers([...new Set(compData.map(item => item.maker))])
        }
        if (monitorData != null) {
            setMonitorMakers([...new Set(monitorData.map(item => item.maker))])
        }
        if (accessData != null) {
            setAccessMakers([...new Set(accessData.map(item => item.maker))])
        }
        if (networkData != null) {
            setNetworkMakers([...new Set(networkData.map(item => item.maker))])
        }
        if (extDeviceData != null) {
            setExtMakers([...new Set(extDeviceData.map(item => item.maker))])
        }
    }, [compData, monitorData, accessData, networkData, extDeviceData])

    const addbuild = (bindex) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        temporder.builds.splice(bindex + 1, 0, defbuild)
        setupdatedorder(temporder)
    }

    const removebuild = (bindex) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        temporder.builds.splice(bindex, 1)
        temporder = updatetotal(temporder)
        setupdatedorder(temporder)
    }

    const updatebuild = (bindex, key, e, num = false) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        temporder.builds[bindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "price" || key === "quantity") {
            temporder.builds[bindex].buildprice = temporder.builds[bindex].price
            let totalprice = temporder.builds[bindex].price * temporder.builds[bindex].quantity
            temporder.builds[bindex].pricebgst = round("round", temporder.builds[bindex].price / 1.18, 2)
            temporder.builds[bindex].totalprice = totalprice
            temporder.builds[bindex].totalpricebgst = round("round", totalprice / 1.18, 2)
            temporder = updatetotal(temporder)
        }
        setupdatedorder(temporder)
    }

    const addcomp = (bindex, cindex) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        temporder.builds[bindex].components.splice(cindex + 1, 0, { "type": "", "maker": "", "productid": "", "quantity": 1 })
        temporder = updatetotal(temporder)
        setupdatedorder(temporder)
    }

    const removecomp = (bindex, cindex) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        if (temporder.builds[bindex].components.length === 1) {
            delete temporder.builds[bindex]
        } else {
            temporder.builds[bindex].components.splice(cindex, 1)
        }
        temporder = updatetotal(temporder)
        setupdatedorder(temporder)
    }

    const updatecomp = (bindex, cindex, key, e, num = false) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        temporder.builds[bindex].components[cindex][key] = num ? Number(e.target.value) : e.target.value
        setupdatedorder(temporder)
    }

    const updateBuildcomp = (bindex, cindex, key, e) => {
        let productid = e.value
        let temporder = { ...updatedorder }
        let collData = compData
        if (temporder.builds[bindex].components[cindex].collection === "networking") {
            collData = networkData
        }
        temporder.builds[bindex].components[cindex][key] = productid
        if (key === "productid") {
            temporder.builds[bindex].components[cindex].title = (temporder.builds[bindex].components[cindex][key] === "other") ? "" : collData.filter(item => item.productid === productid)[0].title
        }
        setupdatedorder(temporder)
    }

    const compOptions = (comp) => {
        const tempArray = []
        let collData = compData
        if (comp.collection === "networking") {
            collData = networkData
        }
        collData !== null && collData.filter(item => item.type === comp.type && item.maker === comp.maker).map(item => {
            return (
                tempArray.push({ value: item.productid, label: item.title })
            )
        })
        tempArray.push({ value: 'other', label: 'Other' })
        return tempArray
    }

    const addprod = (pindex) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        temporder.products.splice(pindex + 1, 0, { "collection": "", "type": "", "category": "", "maker": "", "productid": "", "title": "", "quantity": 1, "prodprice": 0, "discount": 0, "pricebgst": 0, "price": 0, "totaldiscount": 0, "totalpricebgst": 0, "totalprice": 0, "tax_rate": 18, "hsncode": "" })
        temporder = updatetotal(temporder)
        setupdatedorder(temporder)
    }

    const removeprod = (index) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        temporder.products.splice(index, 1)
        temporder = updatetotal(temporder)
        setupdatedorder(temporder)
    }

    const updateprod = (pindex, key, e, num = false) => {
        seterrormsg("")
        let temporder = { ...updatedorder }
        temporder.products[pindex][key] = num ? Number(e.target.value) : e.target.value
        if (key === "price" || key === "quantity") {
            const quantity = temporder.products[pindex].quantity
            const price = temporder.products[pindex].price
            const totalprice = price * quantity
            temporder.products[pindex].pricebgst = round("round", price / (1 + (temporder.products[pindex].tax_rate / 100)), 2)
            temporder.products[pindex].prodprice = price
            temporder.products[pindex].totalpricebgst = round("round", totalprice / (1 + (temporder.products[pindex].tax_rate / 100)), 2)
            temporder.products[pindex].totalprice = totalprice
            temporder = updatetotal(temporder)
        }
        setupdatedorder(temporder)
    }

    const handleBack = (() => {
        history.goBack();
    })

    const updateselectprod = (pindex, e) => {
        const prodid = e.value
        let temporder = { ...updatedorder }
        temporder.products[pindex].productid = prodid
        let collData = compData
        let collection = temporder.products[pindex]["collection"]
        if (collection === "monitors") {
            collData = monitorData
        } else if (collection === "accessories") {
            collData = accessData
        } else if (collection === "networking") {
            collData = networkData
        } else if (collection === "external") {
            collData = extDeviceData
        }
        if (e) {
            temporder.products[pindex].title = (prodid === "other") ? "" : collData.filter(item => item.productid === temporder.products[pindex].productid)[0].title
        } else {
            temporder.products[pindex].title = ''
        }
        setupdatedorder(temporder)
    }

    const selectionOptions = (prod) => {
        const tempArray = []
        if (prod.collection === 'monitors') {
            monitorData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        if (prod.collection === 'components') {
            compData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        if (prod.collection === 'accessories') {
            accessData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        if (prod.collection === 'networking') {
            networkData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        if (prod.collection === 'external') {
            extDeviceData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        tempArray.push({ value: 'other', label: 'Other' })
        return tempArray
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const submitHandler = () => {
        let submit = true

        const builds = orderData.builds
        if (builds && builds.length > 0) {
            for (let i = 0; i < builds.length; i++) {
                if (builds[i].title === "") {
                    submit = false
                    break
                } else {
                    for (let j = 0; j < builds[i].components.length; j++) {
                        if (builds[i].components[j].productid === "" || builds[i].components[j].title === "") {
                            submit = false
                            break
                        }
                    }
                }
                if (submit === false) {
                    break
                }
            }
        }
        const products = orderData.products
        if (submit && products && products.length > 0) {
            for (let i = 0; i < products.length; i++) {
                if (products[i].productid === "" || products[i].title === "") {
                    submit = false
                    break
                }
            }
        }

        let tporder = { ...updatedorder }

        tporder.order_status = "Products Added"

        if (submit) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
            axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders?orderid=' + tporder.orderid, tporder, config).then(res => {
                let order = res.data[0]
                setorderData(order)
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
            setsubmitted(true)
        } else {
            seterrormsg("*Please Enter all the fields")
        }
    }

    return (
        <div className="add-products txt-light-grey">
            {orderData !== null &&
                <>
                    {(orderData.order_status !== "Created" || submitted) ? (
                        <div>
                            <p>Products have been added successfully</p>
                            <KuroLink to='/tporders'>Go to Orders</KuroLink>
                        </div>
                    ) : (
                        <div>
                            {updatedorder !== null &&
                                <>
                                    <table cellPadding="0" cellSpacing="0" className="border">
                                        <tbody>
                                            <tr>
                                                <th>Order Id</th>
                                                <th>Order Date</th>
                                                <th>Channel</th>
                                                <th>TP Order Id</th>
                                                <th>Customer Name</th>
                                                <th>Mobile</th>
                                                <th>Order Total</th>
                                                <th>Order Status</th>
                                                <th>Dispatch Due Date</th>
                                            </tr>
                                            <tr>
                                                <td>{updatedorder.orderid.toUpperCase()}</td>
                                                <td>{updatedorder.order_date === "" ? "" : moment(updatedorder.order_date).format('DD-MM-YYYY')}</td>
                                                <td>{updatedorder.channel}</td>
                                                <td>{updatedorder.tporderid}</td>
                                                <td>{updatedorder.user.name}</td>
                                                <td>{updatedorder.user.phone}</td>
                                                <td>{updatedorder.totalprice}</td>
                                                <td>{updatedorder.order_status}</td>
                                                <td>{updatedorder.dispatchby_date === "" ? "" : moment(updatedorder.dispatchby_date).format('DD-MM-YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>&nbsp;</p>
                                    <div className="products">
                                        <div className='title_wrap col_mob'>
                                            <p>Builds</p>
                                            <span className="prod-span" onClick={() => addbuild(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                        </div>
                                        {updatedorder.builds.map((build, j) =>
                                            <table key={j} className="build border" cellPadding="0" cellSpacing="0">
                                                <tbody>
                                                    <tr>
                                                        <td>Title</td>
                                                        <td colSpan="3"><textarea className="x-large" value={build.title} onChange={(e) => updatebuild(j, "title", e)} /></td>
                                                        <td><span className="prod-span" onClick={() => removebuild(j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Price</td>
                                                        <td><input type="number" className="large" onWheel={disableScroll} value={build.price} onChange={(e) => updatebuild(j, "price", e, true)} /></td>
                                                        <td>Quantity</td>
                                                        <td><input className="small" type="number" onWheel={disableScroll} value={build.quantity} onChange={(e) => updatebuild(j, "quantity", e, true)} /></td>
                                                        <td></td>
                                                    </tr>
                                                    {build.components.map((comp, k) =>
                                                        <tr key={k}>
                                                            <td>
                                                                {comp.collection === "components" &&
                                                                    <select className="large" value={comp.type} onChange={(e) => updatecomp(j, k, "type", e)}>
                                                                        <option value="">Select Type</option>
                                                                        <option value="cpu">Processor</option>
                                                                        <option value="mob">Motherboard</option>
                                                                        <option value="ram">RAM</option>
                                                                        <option value="gpu">Graphics Card</option>
                                                                        <option value="psu">Power Supply</option>
                                                                        <option value="ssd">SSD</option>
                                                                        <option value="hdd">HDD</option>
                                                                        <option value="cooler">CPU Cooler</option>
                                                                        <option value="tower">Case</option>
                                                                        <option value="fan">Fan</option>
                                                                    </select>
                                                                }
                                                                {comp.collection === "networking" && "WiFi Card"}
                                                            </td>
                                                            <td>
                                                                <select className="large" value={comp.maker} onChange={(e) => updatecomp(j, k, "maker", e)}>
                                                                    <option value="">Select Brand</option>
                                                                    {comp.collection === "components" && compMakers !== null && compMakers.map((maker, l) =>
                                                                        compData.filter(item => item.type === comp.type && item.maker === maker).length > 0 && <option key={l} value={maker}>{maker}</option>
                                                                    )}
                                                                    {comp.collection === "networking" && networkMakers !== null && networkMakers.map((maker, l) =>
                                                                        <option key={l} value={maker}>{maker}</option>
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <Select classNamePrefix="kuro-search-select" options={compOptions(comp)} value={{ value: comp.productid, label: comp.productid === "other" ? "Other" : comp.title }} onChange={(e) => updateBuildcomp(j, k, "productid", e)} placeholder="Select Model" className="react-select-container" />
                                                            </td>
                                                            <td>
                                                                <input type="number" className="small" min="1" max="100" onWheel={disableScroll} value={comp.quantity} onChange={(e) => updatecomp(j, k, "quantity", e, true)} />
                                                            </td>
                                                            <td>
                                                                <span className="prod-span" onClick={() => removecomp(j, k)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                                <span className="prod-span" onClick={() => addcomp(j, k)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        )}
                                        <div className='title_wrap col_mob'>
                                            <p>Peripherals</p>
                                            <span className="prod-span" onClick={() => addprod(-1)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                        </div>
                                        {orderData.products.length > 0 &&
                                            <table className="border" cellSpacing="0" cellPadding="0">
                                                <tbody>
                                                    <tr>
                                                        <td>Collection</td>
                                                        <td>Type</td>
                                                        <td>Category</td>
                                                        <td>Brand</td>
                                                        <td>Model</td>
                                                        <td>Quantity</td>
                                                        <td>Price</td>
                                                        <td>Tax Rate</td>
                                                        <td></td>
                                                    </tr>
                                                    {orderData.products.map((prod, i) =>
                                                        <tr key={i}>
                                                            <td>
                                                                <select value={prod.collection} onChange={(e) => updateprod(i, "collection", e)}>
                                                                    <option value="">Select Collection</option>
                                                                    <option value="components">Components</option>
                                                                    <option value="monitors">Monitors</option>
                                                                    <option value="accessories">Accessories</option>
                                                                    <option value="networking">Networking</option>
                                                                    <option value="external">External Devices</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select value={prod.type} onChange={(e) => updateprod(i, "type", e)}>
                                                                    <option value="">Select Type</option>
                                                                    {prod.collection === "components" &&
                                                                        <>
                                                                            <option value="cpu">Processor</option>
                                                                            <option value="mob">Motherboard</option>
                                                                            <option value="ram">RAM</option>
                                                                            <option value="gpu">Graphics Card</option>
                                                                            <option value="psu">Power Supply</option>
                                                                            <option value="ssd">SSD</option>
                                                                            <option value="hdd">HDD</option>
                                                                            <option value="cooler">CPU Cooler</option>
                                                                            <option value="tower">Case</option>
                                                                            <option value="fan">Fan</option>
                                                                        </>
                                                                    }
                                                                    {prod.collection === "monitors" && <option value="monitor">Monitor</option>}
                                                                    {prod.collection === "accessories" &&
                                                                        <>
                                                                            <option value="keyboard">Keyboard</option>
                                                                            <option value="keyboard-accessories">Keyboard Accessories</option>
                                                                            <option value="mouse">Mouse</option>
                                                                            <option value="mouse-accessories">Mouse Accessories</option>
                                                                            <option value="kbdmouse">Keyboard Mouse Combo</option>
                                                                            <option value="streaming-gear">Streaming Gear</option>
                                                                        </>
                                                                    }
                                                                    {prod.collection === "networking" && <option value="wifi-card">Wifi Card</option>}
                                                                    {prod.collection === "external" &&
                                                                        <>
                                                                            <option value="hdd">HDD</option>
                                                                            <option value="expansion-card">Expansion Card</option>
                                                                        </>
                                                                    }
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select value={prod.category} onChange={(e) => updateprod(i, "category", e)}>
                                                                    <option value="">Select Category</option>
                                                                    {prod.type === "streaming-gear" &&
                                                                        <>
                                                                            <option value="capture-card">Capture Card</option>
                                                                            <option value="gaming-chair">Gaming Chair</option>
                                                                            <option value="streaming-mic">Streaming Mic</option>
                                                                            <option value="webcam">Webcam</option>
                                                                            <option value="gaming-headset">Gaming Headset</option>
                                                                        </>
                                                                    }
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select value={prod.maker} onChange={(e) => updateprod(i, "maker", e, false)}>
                                                                    <option value="">Select Brand</option>
                                                                    {prod.collection === "components" && compMakers !== null && compMakers.map((maker, j) =>
                                                                        compData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                                    )}
                                                                    {prod.collection === "monitors" && monitorMakers !== null && monitorMakers.map((maker, j) =>
                                                                        monitorData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                                    )}
                                                                    {prod.collection === "accessories" && accessMakers !== null && accessMakers.map((maker, j) =>
                                                                        accessData.filter(item => item.type === prod.type && item.category === prod.category && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                                    )}
                                                                    {prod.collection === "networking" && networkMakers !== null && networkMakers.map((maker, j) =>
                                                                        networkData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                                    )}
                                                                    {prod.collection === "external" && extMakers !== null && extMakers.map((maker, j) =>
                                                                        networkData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <Select classNamePrefix="kuro-search-select" options={selectionOptions(prod)} value={{ value: prod.productid, label: prod.productid === "other" ? "Other" : prod.title }} onChange={(e) => updateselectprod(i, e)} placeholder="Select Model" className="react-select-container" />
                                                            </td>
                                                            <td>
                                                                <input type="number" className="small" min="1" max="100" onWheel={disableScroll} value={prod.quantity} onChange={(e) => updateprod(i, "quantity", e, true)} />
                                                            </td>
                                                            <td>
                                                                <input type="number" className="small" onWheel={disableScroll} value={prod.price} onChange={(e) => updateprod(i, "price", e, true)} />
                                                            </td>
                                                            <td>
                                                                <select value={prod.tax_rate} onChange={(e) => updateprod(i, "tax_rate", e)}>
                                                                    <option value={5}>5</option>
                                                                    <option value={12}>12</option>
                                                                    <option value={18}>18</option>
                                                                    <option value={28}>28</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <span className="prod-span" onClick={() => removeprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                                <span className="prod-span" onClick={() => addprod(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                    <p>&nbsp;</p>
                                    <ul className='btns'>
                                        <li>
                                            <button onClick={submitHandler}>Submit</button>
                                        </li>
                                        <li><button onClick={handleBack}>back</button></li>
                                    </ul>
                                    {errormsg !== "" && <p className='util txt-red'>{errormsg}</p>}
                                    <p>&nbsp;</p>
                                </>
                            }
                        </div>
                    )}
                </>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(TPOrderProducts)