import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import note from '../assets/img/button.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
moment.tz.setDefault('Asia/Kolkata')

const OutwardInvoices = ({user: {token, userDetails}}) => {
const history=useHistory();
    const [invoicesData, setinvoicesData] = useState(null)
    const [fltestdata, setfltestdata] = useState(null)
    const [searchtxt, setsearchtxt] = useState("")
    const [limit, setlimit] = useState(30)
const access=userDetails.accesslevel.outward_invoices;

useEffect(()=>{
if(access==="NA"){
    history.push("/access-denied")
    return;
}
}, [])
    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices?limit=' + limit, config).then(res => {
            setinvoicesData(res.data)
            if (res.data.length < limit) {
                setlimit(0)
            }
        }).catch((err)=>{
            if(err.response.status===401){
                history.push("/unauthorized")
            }
        })
    }, [limit])

    useEffect(() => {
        if (searchtxt === "") {
            setfltestdata(invoicesData)
        } else {
            setlimit(0)
            setfltestdata(invoicesData.filter((invoice) => invoice.invoice_no.toLowerCase().includes(searchtxt) || invoice.billadd.name.toLowerCase().includes(searchtxt) || ((invoice.user.phone === undefined) ? "" : invoice.user.phone.includes(searchtxt))))
        }
    }, [searchtxt, invoicesData])

    const limitHandler = (all) =>{
        if (all) {
            setlimit(0)
        } else {
            setlimit(limit + 30)
        }
    }

    var fileDownload = require('js-file-download')

    const downloadinvoice = (invoice_no, fin_year) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardinvoices?invoice_no=' + invoice_no + "&fin_year=" + fin_year + "&download=true", config)
        .then((res) => {
            fileDownload(res.data, invoice_no + '.pdf')
        }).catch((err)=>{
            if(err.response.status===401){
                history.push("/unauthorized")
            }
        })
    }

    return (
        <div className='txt-light-grey'>
            <h2>Sales Invoices</h2>
            <div className="note"><p>Instructions </p> 
                <img src={note}  className='note_img'/>:
                     <span className="note_text">lorem text</span>
                </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            <div className='search_container'>
                <input type='text' name='search' onChange = {(e) => setsearchtxt(e.target.value.toLowerCase())} placeholder="Search......"/>
            </div>
            {(access==="write" || access==="edit" )&&   <ul className='btns'>
                <li><KuroLink to="/generate-invoice"><button>Generate Invoice</button></KuroLink></li>
            </ul>}
           
            <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <th>Invoice No</th>
                        <th>Invoice Date</th>
                        <th>Customer Name</th>
                        <th>Mobile Number</th>
                        <th>Invoice Total</th>
                        <th></th>
                        {userDetails.access==="Super" &&    <th></th>}
                     
                    </tr>
                    {fltestdata !== null && fltestdata.map((invoice, i) =>
                    <tr key={i}>
                        <td><KuroLink to={'/outward-invoices/' + invoice.fin_year + '/' + invoice.invoice_no} >{invoice.invoice_no}</KuroLink></td>
                        <td>{moment(invoice.invoice_date).format('DD-MM-YYYY')}</td>
                        <td>{invoice.user.name}{invoice.billadd.company === "" ? "" : " [" + invoice.billadd.company + "]"}</td>
                        <td>{invoice.user.phone}</td>
                        <td>{invoice.totalprice}</td>
                        <td>
                            <ul className='btns'>
                                <li>
                                    <button onClick={() => downloadinvoice(invoice.invoice_no, invoice.fin_year)} className="button_mob">Download Invoice</button>
                                </li>
                            </ul>
                        </td>
                        {userDetails.access==="Super" &&  <td>
                            <ul className='btns'>
                                {invoice.creditnote_issued === true ? (
                                <li>
                                    <b style={{padding : "20px"}}>Credit Note is issued</b>
                                </li>
                                )
                                 : (
                                <li>
                                    <KuroLink classList="btn pri1 solid" to={'invoice-credit/' + invoice.fin_year + '/' + invoice.invoice_no}>Generate Credit Note</KuroLink>
                                </li>
                                )}
                            </ul>
                        </td> }
                       
                    </tr>
                    )}
                </tbody>
            </table>
            {limit !== 0 && invoicesData !== null &&
                <div className='limit_handler'>
                    <button className='btn' onClick={() => limitHandler(false)}>Show More</button>
                    <button className='btn' onClick={() => limitHandler(true)}>Show All</button>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(OutwardInvoices)