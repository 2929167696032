import { UPDATE_COMPONENTS, UPDATE_MONITORS, UPDATE_ACCESSORIES, UPDATE_NETWORKING, UPDATE_EXTERNAL, UPDATE_PRESETS, UPDATE_PRODUCTS } from '../actions/types';

const initialState = {
    compData: null,
    monitorData: null,
    accessData: null,
    networkData: null,
    extDeviceData: null,
    presetData: null
};

const prods = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_COMPONENTS:
            return {
                ...state,
                compData: action.payload
            }
        case UPDATE_MONITORS:
            return {
                ...state,
                monitorData: action.payload
            }
        case UPDATE_ACCESSORIES:
            return {
                ...state,
                accessData: action.payload
            }
        case UPDATE_NETWORKING:
            return {
                ...state,
                networkData: action.payload
            }
        case UPDATE_EXTERNAL:
            return {
                ...state,
                extDeviceData: action.payload
            }
        case UPDATE_PRESETS:
            return {
                ...state,
                presetData: action.payload
            }
        case UPDATE_PRODUCTS:
            return {
                ...state,
                compData: action.payload.components,
                monitorData: action.payload.monitors,
                accessData: action.payload.accessories,
                networkData: action.payload.networking,
                extDeviceData: action.payload.external,
                presetData: action.payload.presets
            }
        default:
            return state;
    }
}

export default prods;