import axios from 'axios'
import { UPDATE_PROVINCES, UPDATE_VENDORS, UPDATE_INWARD_INVOICES, UPDATE_EMPLOYEES, UPDATE_STAFF_DATA } from './types'
import store from '../store'

// Get the States
export const getStates = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KC_API_URL + 'kurostaff/states', tokenConfig())
        .then((res) => {
            dispatch({
                type: UPDATE_PROVINCES,
                payload: res.data,
            })
        })
        .catch((err) => {
        })
}

// Get the Vendors
export const getVendors = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KC_API_URL + 'kurostaff/vendors', tokenConfig())
        .then((res) => {
            dispatch({
                type: UPDATE_VENDORS,
                payload: res.data,
            })
        })
        .catch((err) => {
        })
}

// Get the Invoices
export const getInwardInvoices = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardinvoices', tokenConfig())
        .then((res) => {
            dispatch({
                type: UPDATE_INWARD_INVOICES,
                payload: res.data,
            })
        })
        .catch((err) => {
        })
}

export const getStaffData = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/storedata', tokenConfig())
        .then((res) => {
            dispatch({
                type: UPDATE_STAFF_DATA,
                payload: res.data,
            })
        })
        .catch((err) => {
        })
}

// Get the Employees
export const getEmployees = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/employees', tokenConfig())
        .then((res) => {
            dispatch({
                type: UPDATE_EMPLOYEES,
                payload: res.data,
            })
        })
        .catch((err) => {
        })
}

// Setup config with token - helper function
export const tokenConfig = () => {
    // Get token from state
    const token = store.getState().user.token

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    // If token, add to headers config
    if (token) {
        config.headers['Authorization'] = `Token ${token}`
    }

    return config
}