import { UPDATE_PROVINCES, UPDATE_VENDORS, UPDATE_INWARD_INVOICES, UPDATE_EMPLOYEES, UPDATE_STAFF_DATA } from '../actions/types'

const initialState = {
    provinces: [],
    vendors: null,
    inwardinvoices: null,
    employees: null
}

const admin = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PROVINCES:
            return {
                ...state,
                provinces: action.payload
            }
        case UPDATE_VENDORS:
            return {
                ...state,
                vendors: action.payload
            }
        case UPDATE_INWARD_INVOICES:
            return {
                ...state,
                inwardinvoices: action.payload
            }
        case UPDATE_EMPLOYEES:
            return {
                ...state,
                employees: action.payload
            }
        case UPDATE_STAFF_DATA:
            return {
                ...state,
                employees: action.payload.empolyees,
                inwardinvoices: action.payload.inwardinvoices,
                provinces: action.payload.provinces
            }
        default:
            return state
    }
}

export default admin