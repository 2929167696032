import axios from 'axios';
import '../styles/itc-gst.css'
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as XLSX from 'xlsx-js-style';
import fileDownload from 'js-file-download';

const ItcGst = ({ user: { token } }) => {

    const [fileReceived, setFileReceived] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [excelStyles, setExcelStyles] = useState([]);
    const [inwardInvoices, setInwardInvoices] = useState([])
    const history = useHistory();

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardinvoices', config).then(res => {
            setInwardInvoices(res.data[0].invoices)
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })

    }, [])

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const workbook = parseExcelFile(e.target.result);
                const worksheet = getFirstSheet(workbook);
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                const conditions = filterExcelData(jsonData);

                applyStylesToWorksheet(worksheet, conditions);
                const newWorksheet = getFirstSheet(workbook);
                const newJsonData = XLSX.utils.sheet_to_json(newWorksheet, { header: 1, raw: true });
                const styles = extractStyles(newWorksheet);

                setExcelStyles(styles);
                setExcelData(newJsonData);

                const newWorkbook = JSON.parse(JSON.stringify(workbook));
                downloadFile(XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' }), "itc-gst.xlsx");

                updateItc(conditions);
                setFileReceived(true);
            } catch (error) {
                console.error("Error processing file:", error);
            }
        };

        reader.readAsArrayBuffer(file);
    };

    const parseExcelFile = (fileData) => {
        const data = new Uint8Array(fileData);
        return XLSX.read(data, { type: 'array' });
    };

    const getFirstSheet = (workbook) => {
        const sheetName = workbook.SheetNames[0];
        return workbook.Sheets[sheetName];
    };

    const filterExcelData = (excelJsonData) => {
        const newFilteredData = {
            correct: [],
            incorrect: [],
            notFound: []
        };

        excelJsonData.forEach((ele) => {
            const exInvoiceNo = ele["Invoice number"];
            const exGstin = ele["GSTIN of supplier"];
            const exSgst = ele["State/UT Tax(₹)"];
            const exCgst = ele["Central Tax(₹)"];
            const exIgst = ele["Integrated Tax(₹)"];
            const exTotalPrice = ele["Invoice Value(₹)"];

            const matchingInvoice = inwardInvoices.find((inv) => inv.invoice_no === exInvoiceNo);

            if (matchingInvoice) {
                if (
                    exGstin === matchingInvoice.gstin &&
                    exSgst === matchingInvoice.sgst &&
                    exCgst === matchingInvoice.cgst &&
                    exIgst === matchingInvoice.igst &&
                    exTotalPrice === matchingInvoice.totalprice
                ) {
                    newFilteredData.correct.push(exInvoiceNo);
                } else {
                    switch (true) {
                        case exGstin !== matchingInvoice.gstin:
                            newFilteredData.incorrect.push({ key: "GSTIN of supplier", value: exInvoiceNo });
                            break;
                        case exSgst !== matchingInvoice.sgst:
                            newFilteredData.incorrect.push({ key: "State/UT Tax(₹)", value: exInvoiceNo });
                            break;
                        case exCgst !== matchingInvoice.cgst:
                            newFilteredData.incorrect.push({ key: "Central Tax(₹)", value: exInvoiceNo });
                            break;
                        case exIgst !== matchingInvoice.igst:
                            newFilteredData.incorrect.push({ key: "Integrated Tax(₹)", value: exInvoiceNo });
                            break;
                        case exTotalPrice !== matchingInvoice.totalprice:
                            newFilteredData.incorrect.push({ key: "Invoice Value(₹)", value: exInvoiceNo });
                            break;
                        default:
                            break;
                    }
                }
            } else {
                newFilteredData.notFound.push(exInvoiceNo);
            }
        });

        return newFilteredData;
    };

    const applyStylesToWorksheet = (worksheet, conditions) => {
        const styles = {
            correct: getStyle("00FF00"),
            incorrect: getStyle("FFFF00"),
            notFound: getStyle("FF0000"),
        };

        const range = XLSX.utils.decode_range(worksheet['!ref']);
        const invoiceNumberColumn = findColumnIndex(worksheet, "Invoice number");

        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
            const cellRef = getCellRef(worksheet, rowNum, invoiceNumberColumn);
            if (!cellRef) continue;

            const cellValue = worksheet[cellRef].v;

            if (conditions.correct.includes(cellValue)) {
                applyStyleToRow(worksheet, rowNum, range, styles.correct);
            } else if (conditions.notFound.includes(cellValue)) {
                applyStyleToRow(worksheet, rowNum, range, styles.notFound);
            }
        }

        conditions.incorrect.forEach(incorrectItem => {
            const { key, value } = incorrectItem;

            const incorrectColumn = findColumnIndex(worksheet, key);

            for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                const invoiceCellRef = getCellRef(worksheet, rowNum, invoiceNumberColumn);

                if (worksheet[invoiceCellRef] && worksheet[invoiceCellRef].v === value) {

                    const incorrectCellRef = getCellRef(worksheet, rowNum, incorrectColumn);
                    applyStyleToRow(worksheet, rowNum, range, styles.correct);

                    if (!worksheet[incorrectCellRef]) {
                        worksheet[incorrectCellRef] = { v: "-", s: styles.incorrect };
                    } else if (worksheet[incorrectCellRef].v) worksheet[incorrectCellRef].s = styles.incorrect;
                }
            }
        });
    };

    const extractStyles = (worksheet) => {
        const styles = {};
        const range = XLSX.utils.decode_range(worksheet['!ref']);

        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
                const cell = worksheet[cellRef];

                if (cell && cell.s) {
                    styles[cellRef] = cell.s;
                }
            }
        }

        return styles;
    };

    const getCellStyle = (cellRef) => {
        const cellStyle = excelStyles[cellRef];
        if (!cellStyle) return {};

        const style = {};
        if (cellStyle.fill && cellStyle.fill.fgColor && cellStyle.fill.fgColor.rgb) {
            style.backgroundColor = `#${cellStyle.fill.fgColor.rgb}`;
        }
        return style;
    };

    const getStyle = (color) => ({
        fill: {
            patternType: "solid",
            fgColor: { rgb: color },
        }
    });

    const getCellRef = (worksheet, rowNum, columnNum) => {
        const cellAddress = { r: rowNum, c: columnNum };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        return cellRef;
    };


    const applyStyleToRow = (worksheet, rowNum, range, rowStyle) => {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
            const cellAddress = { r: rowNum, c: colNum };
            const cellRef = XLSX.utils.encode_cell(cellAddress);

            if (worksheet[cellRef]) {
                worksheet[cellRef].s = rowStyle;
            }
        }
    };

    const downloadFile = (workbook, filename) => {
        fileDownload(workbook, filename);
    };



    const updateItc = (conditions) => {
        let body = [];

        inwardInvoices.forEach((invoice) => {
            conditions.correct.forEach((ele) => {
                if (invoice.invoice_no === ele) {
                    body.push({
                        "invoiceid": invoice.invoiceid,
                        "finyear": invoice.fin_year
                    });
                }
            });
        });
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        };

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/uploadinvoices?check=true', body, config)
            .then(res => {
                history.push("/itc-gst");
            })
            .catch((err) => {
                console.error('An error occurred:', err);
            });
    };

    function findColumnIndex(worksheet, headerName) {

        const range = XLSX.utils.decode_range(worksheet['!ref']);
        let columnIndex = -1;

        for (let C = range.s.c; C <= range.e.c; ++C) {

            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
            const cell = worksheet[cellAddress];

            if (cell && cell.v === headerName) {
                columnIndex = C;
                break;
            }
        }

        return columnIndex;
    }

    const handleReset = () => {
        setFileReceived(false);
    }



    return (
        <div className='itc-gst-wrapper'>
            {!fileReceived ? (
                <div className='itc-gst'>
                    <div className="file-input">
                        <input
                            type="file"
                            accept=".xls, .xlsx"
                            placeholder="Upload"
                            onChange={handleFileUpload}
                        />
                        <svg
                            width="60"
                            height="60"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M48.75 25.9277V48.75C48.75 50.2418 48.1574 51.6726 47.1025 52.7275C46.0476 53.7824 44.6168 54.375 43.125 54.375H16.875C15.3832 54.375 13.9524 53.7824 12.8975 52.7275C11.8426 51.6726 11.25 50.2418 11.25 48.75V11.25C11.25 9.75816 11.8426 8.32742 12.8975 7.27252C13.9524 6.21763 15.3832 5.625 16.875 5.625H28.4473C29.4415 5.62515 30.3949 6.02011 31.098 6.72305L47.652 23.277C48.3549 23.9801 48.7498 24.9335 48.75 25.9277Z"
                                stroke="#FBFBFB"
                                strokeWidth="2"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M30 6.5625V20.625C30 21.6196 30.3951 22.5734 31.0984 23.2766C31.8016 23.9799 32.7554 24.375 33.75 24.375H47.8125"
                                stroke="#FBFBFB"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <h3>Drag and drop or click to browse</h3>
                    </div>
                </div>
            ) : (
                <div className="data-table">
                    {excelData.length > 0 && (
                        <table className='border' style={{ marginTop: '20px' }}>
                            <tbody>
                                {excelData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((cell, colIndex) => {
                                            const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
                                            return (
                                                <td key={colIndex} style={getCellStyle(cellRef)}>
                                                    {cell}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    {/* <button onClick={() => downloadExcelFile(excelData)}>Download Excel</button> */}
                    <div className="info">
                        <div>
                            <span className='box green'>

                            </span>
                            Correct
                        </div>
                        <div>
                            <span className='box yellow'>

                            </span>
                            Incorrect
                        </div>
                        <div>
                            <span className='box red'>

                            </span>
                            Not Found
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <button onClick={handleReset}>Reset</button>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(ItcGst);

