import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { connect } from 'react-redux'
import axios from 'axios'
import { utils, writeFile } from 'xlsx'
import { getVendors } from '../actions/admin'
import '../styles/table.css'
import '../styles/tabs.css'
import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Kolkata')

const BulkPayments = ({ user: { token, userDetails }, admin: { vendors, inwardinvoices }, getVendors }) => {

    const [selectAll, setSelectAll] = useState(false)
    const [selectedInvoices, setselectedInvoices] = useState([])
    const [selectedTotal, setselectedTotal] = useState(0)
    const history=useHistory();
    const updateTotal = (invoices) => {
        let total = 0
        for (let inv of invoices) {
            total += inwardinvoices[0].pending_payments.filter(_ => _.invoiceid === inv)[0].totalprice
        }
        setselectedTotal(total)
    }

    const invoiceSelectAll = (value) => {
        let tempinvoices = [...selectedInvoices]
        if (value) {
            tempinvoices = inwardinvoices[0].pending_payments.map((inv) => {return inv.invoiceid})
        } else {
            tempinvoices = []
        }
        updateTotal(tempinvoices)
        setselectedInvoices(tempinvoices)
        setSelectAll(value)
    }

    const invoiceSelect = (invoiceid) => {
        let tempinvoices = [...selectedInvoices]
        if (tempinvoices.indexOf(invoiceid) < 0) {
            tempinvoices.push(invoiceid)
        } else {
            tempinvoices = tempinvoices.filter(inv => inv !== invoiceid)
            setSelectAll(false)
        }
        updateTotal(tempinvoices)
        setselectedInvoices(tempinvoices)
    }

    useEffect(() => {
        const access=userDetails.access;
        if(access!=="Super"){
            history.push('/unauthorized')
            return;
        }
        getVendors()
    }, [])

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let data = selectedInvoices
        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/bulk_payments', data, config)
        .then((res) => {
            const wb = utils.book_new()
            const ws = utils.json_to_sheet(res.data)
            /* create workbook and append worksheet */
            utils.book_append_sheet(wb, ws, 'Payments')
            /* export to XLSX */
            writeFile(wb, 'Payments.xlsx')

        })
        .catch((err)=>  {if(err.response.status===401){
            history.push("/unauthorized")}})
    }


    return (
        <div className='audit mx-width txt-light-grey'>
        <h2>Bulk Payments</h2>
        {inwardinvoices !== null &&
            <div>
                <table className='txt-light' cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td><input type="checkbox" checked={selectAll} onChange={() => invoiceSelectAll(!selectAll)}/></td>
                            <td><h4>Select All</h4></td>
                            <td>&nbsp;</td>
                            <td><h4>Pending Payments: {inwardinvoices[0].pendings.payments}</h4></td>
                            <td>&nbsp;</td>
                            <td><h4>Selected Amount: {selectedTotal}</h4></td>
                        </tr>
                    </tbody>
                </table>
                <table className="border even_odd" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                        <th></th>
                        <th>Invoice Id</th>
                        <th>Invoice No.</th>
                        <th>Invoice Date</th>
                        <th>PO Number</th>
                        <th>Vendor Name</th>
                        <th>Invoice Total</th>
                        <th>Due Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inwardinvoices[0].pending_payments.map((invoice, i) => (
                        <tr key={i}>
                            <td>
                                <input type="checkbox" checked={selectedInvoices.indexOf(invoice.invoiceid) === -1 ? false : true} onChange={() => invoiceSelect(invoice.invoiceid)} />
                            </td>
                            <td>{invoice.invoiceid}</td>
                            <td>{invoice.invoice_no}</td>
                            <td>{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format('DD-MM-YYYY')}</td>
                            <td>{invoice.po_no}</td>
                            <td>{vendors !== null && vendors.filter(item => item.vendor_code === invoice.vendor)[0].name + (invoice.gstin === '' ? '' : ' [' + invoice.gstin + ']')}</td>
                            <td>{invoice.totalprice}</td>
                            <td>{invoice.due_date === "" ? "" : moment(invoice.due_date).format('DD-MM-YYYY')}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                <ul className='btns'>
                    <li>
                        <button onClick={submitHandler}>Submit</button>
                    </li>
                </ul>
            </div>
        }
          
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(BulkPayments)