import { useEffect, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import KuroLink from '../../components/common/KuroLink'
import '../../styles/presets.css'
import { psspecs } from '../../data/Presets'
import { useHistory } from "react-router-dom"

const Presets = ({ user: { userDetails } }) => {

    const location = useLocation()
    const [pstype, setpstype] = useState("")
    const [presetdata, setpresetdata] = useState([])
    const [editPrice, seteditPrice] = useState(null)
    const history = useHistory();

    useEffect(() => {
        if (userDetails.accesslevel.presets === "NA") {
            history.push('/unauthorized')
            return;
        }
        if (location.state && location.state.pstype) {
            const presettype = location.state.pstype
            setpstype(presettype)
        }
    }, [location.state])

    useEffect(() => {
        getpresets(pstype)
    }, [pstype])

    const getpresets = () => {
        if (pstype !== "") {
            const config = {}
            axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets?pstype=' + pstype, config).then(res => {
                setpresetdata(res.data)
            })
        }
    }

    const pstypeHandler = (e) => {
        setpstype(e.target.value)
    }

    const handlePriceChange = (index) => {
        if (editPrice === index) {
            const config = {}

            let newpsData = presetdata[index]
            let presetid = newpsData.presetid

            for (let key of ["title", "kuro_title", "portal_title", "short_title"]) {
                newpsData[key] = newpsData[key].trim()
            }
            for (let spec in newpsData.specs) {
                let pskey = psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0]
                if (pskey) {
                    if (pskey.type === "dec") {
                        newpsData.specs[spec] = parseFloat(newpsData.specs[spec])
                    } else if (pskey.type === "txt") {
                        newpsData.specs[spec] = newpsData.specs[spec].trim()
                    }
                }
            }
            axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets?pstype=' + pstype + '&presetid=' + presetid, newpsData, config)
                .then((res) => {
                    seteditPrice(null)
                })
        }
        else {
            seteditPrice(index)
        }
    }
    const changePrice = (e, index, key) => {
        let tempdata = [...presetdata]
        tempdata[index][key] = e.target.value
        setpresetdata(tempdata)
    }

    return (
        <div className='presets txt-light-grey'>
            <h2>Presets</h2>
            <ul className='btns'>
                {userDetails.access === "Super" ? <li><KuroLink to={'add-preset'}><button>Add Preset</button></KuroLink></li> : null}
            </ul>
            <div className='preset_head'>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <select className='inputsel' value={pstype} onChange={pstypeHandler}>
                                    <option value="">Select Type</option>
                                    <option value="cpu">Processor</option>
                                    <option value="mob">Motherboard</option>
                                    <option value="ram">RAM</option>
                                    <option value="gpu">Graphics Card</option>
                                    <option value="ssd">SSD</option>
                                    <option value="hdd">HDD</option>
                                    <option value="psu">Power Supply</option>
                                    <option value="cooler">Cooler</option>
                                    <option value="tower">Case</option>
                                    <option value="fans">Fans</option>
                                    <option value="wifi">WiFi</option>
                                    <option value="os">Operating System</option>
                                    <option value="shp_fees">Shipping Fees</option>
                                    <option value="build_fees">Build Charges</option>
                                    <option value="warranty">Warranty</option>
                                    <option value="margin">KM</option>
                                </select>
                            </td>
                            <td><button onClick={getpresets}>Get Details</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {pstype && presetdata.length > 0 &&
                <div className='preset_body'>
                    <table className='border table_mob'>
                        <tbody>
                            <tr>
                                <th>Preset Id</th>
                                <th>Customer Title</th>
                                <th>Kuro Title</th>
                                <th>Stock Availability</th>
                                <th>Vendor Pricing</th>
                                <th>Price</th>
                                <th>MRP</th>
                                <th>Calc Price</th>
                                <th></th>
                            </tr>
                            {presetdata.map((pr, i) =>
                                pstype === "margin" ? (
                                    <tr key={i}>
                                        <td>{pr.kmar}</td>
                                    </tr>
                                ) : (
                                    <tr key={i}>
                                        <td><KuroLink to={'/presets/' + pstype + '/' + pr.presetid}>{pr.presetid}</KuroLink></td>
                                        <td>{pr.title}</td>
                                        <td>{pr.kuro_title}</td>
                                        <td>{pr.status}</td>
                                        <td>
                                        {pr.vendor_price && pr.vendor_price.length > 0 ? (
                                            pr.vendor_price.map((vendor, index) => (
                                                <span key={index}>{vendor.vendor} : {vendor.price}</span>
                                            ))
                                        ) : (
                                            "NA"
                                        )}
                                        </td>
                                        <td>{editPrice === i ? <input type="text" value={pr.price} onChange={(e) => changePrice(e, i, 'price')} /> : pr.price}</td>
                                        <td>{pr.mrp}</td>
                                        <td>{pr.calc_price}</td>
                                        <td><button onClick={() => handlePriceChange(i)}>{editPrice === i ? 'Save' : 'Edit'}</button></td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Presets)