import React, { useEffect, useState } from 'react';
import '../styles/table.css';
import { connect } from 'react-redux';
import { getVendors } from '../actions/admin'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Exportdata from '../components/Exportdata';

const Financials = ({ user: { token, userDetails }, admin: { vendors }, getVendors }) => {
    const [finData, setFinData] = useState([]);
    const [accountsData, setAccountsData] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (userDetails.access !== "Super") {
            history.push("/unauthorized");
            return;
        }
        getVendors()
    }, [userDetails.access, history]);

    useEffect(() => {
        const fetchFinancials = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                };
                const { data } = await axios.get(`${process.env.REACT_APP_KC_API_URL}kuroadmin/financials`, config);
                const { data: accounts } = await axios.get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/accounts', config);
                setAccountsData(accounts);
                setFinData(data);
            } catch (error) {
                console.error('Error fetching financial data:', error);
            }
        };
        // Fetch data only if the token is available
        if (token) {
            fetchFinancials();
        }
    }, [token])

    return (
        <div>
            <h2 className="txt-light-grey">Financial Overview</h2>
            <Exportdata />
            {accountsData !== null && finData &&
                <div>
                    <div className="tabs">
                        <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Financials</button>
                        <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Sundry Creditors</button>
                        <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>Sundry Debtors</button>
                        <button onClick={() => setTabIndex(3)} className={"tab txt-grey" + (tabIndex === 3 ? " active" : "")}>Banks</button>
                        <button onClick={() => setTabIndex(4)} className={"tab txt-grey" + (tabIndex === 4 ? " active" : "")}>Partners</button>
                        <button onClick={() => setTabIndex(5)} className={"tab txt-grey" + (tabIndex === 5 ? " active" : "")}>Loans</button>
                    </div>
                    <div className="tab-panels">
                        <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                            <table className='border even_odd' cellPadding={0} cellSpacing={0}>
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Fixed Assets</th>
                                        <th>Trading Stock</th>
                                        <th>Total Expenses</th>
                                        <th>Salaries</th>
                                        <th>Rent</th>
                                        <th>Office Supplies</th>
                                        <th>Logistics</th>
                                        <th>Services</th>
                                        <th>Food & Entertainment</th>
                                        <th>Utilities</th>
                                        <th>Financial Services</th>
                                        <th>Petty Cash</th>
                                        <th>Bank Loan</th>
                                        <th>Govt Fees and Taxes</th>
                                        <th>Govt Penalties</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {finData.map((item, index) => (
                                        Object.entries(item).map(([dateKey, value]) => (
                                            <tr key={index}>
                                                <td>{dateKey}</td>
                                                <td>{value["Fixed Assets"] || 0}</td>
                                                <td>{value["Trading Stock"] || 0}</td>
                                                <td>{value["total"] || 0}</td> {/* Ensure 'total' is corrected in the data */}
                                                <td>{value["Expenses - Salaries"] || 0}</td>
                                                <td>{value["Expenses - Rent"] || 0}</td>
                                                <td>{value["Expenses - Office Supplies"] || 0}</td>
                                                <td>{value["Expenses - Logistics"] || 0}</td>
                                                <td>{value["Expenses - Services"] || 0}</td>
                                                <td>{value["Expenses - Food & Entertainment"] || 0}</td>
                                                <td>{value["Expenses - Utilities"] || 0}</td>
                                                <td>{value["Expenses - Financial Services"] || 0}</td>
                                                <td>{value["Expenses - Petty Cash"] || 0}</td>
                                                <td>{value["Bank Loan"] || 0}</td>
                                                <td>{value["Govt Fees and Taxes"] || 0}</td>
                                                <td>{value["Govt Penalties"] || 0}</td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                            <table cellPadding={0} cellSpacing={0} className='border even_odd'>
                                <thead>
                                    <th>Creditor</th>
                                    <th>Opening Balance</th>
                                    <th>Credit</th>
                                    <th>Debit</th>
                                    <th>Closing Balance</th>
                                </thead>
                                <tbody>
                                    {
                                        accountsData
                                            .filter(item => item.type === "sundrycreditors")
                                            .map(item => item.content)
                                            .flat()
                                            .map(creditData => {
                                                const key = Object.keys(creditData)[0];
                                                const data = creditData[key];
                                                return (
                                                    <tr key={key}>
                                                        <td>{vendors ? vendors.filter(ven => ven.vendor_code === key)[0].name : key}</td>
                                                        <td> {data.opening_balance}</td>
                                                        <td> {data.credit}</td>
                                                        <td>{data.debit}</td>
                                                        <td>{data.closing_balance}</td>
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className={"tab-panel" + (tabIndex === 2 ? " active" : "")}>
                            <table cellPadding={0} cellSpacing={0} className='border even_odd'>
                                <thead>
                                    <th>Debtor</th>
                                    <th>Opening Balance</th>
                                    <th>Credit</th>
                                    <th>Debit</th>
                                    <th>Closing Balance</th>
                                </thead>
                                <tbody>
                                    {
                                        accountsData
                                            .filter(item => item.type === "sundrydebtors")
                                            .map(item => item.content)
                                            .flat()
                                            .map(creditData => {
                                                const key = Object.keys(creditData)[0];
                                                const data = creditData[key];
                                                return (
                                                    <tr key={key}>
                                                        <td>{key}</td>
                                                        <td> {data.opening_balance}</td>
                                                        <td> {data.credit}</td>
                                                        <td>{data.debit}</td>
                                                        <td>{data.closing_balance}</td>
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className={"tab-panel" + (tabIndex === 3 ? " active" : "")}>
                            <>
                                <table style={{ marginBottom: "10px" }} className='border even_odd'>
                                    <tr>
                                        <th>Cash in Hand</th>
                                        <td>{accountsData.filter(item => item.typeofdata === "cash_in_hand").map(item => item.content?.amount)[0] || "No amount available"}</td>
                                    </tr>
                                </table>
                                <table cellPadding={0} cellSpacing={0} className='border even_odd'>
                                    <thead>
                                        <th>Bank Id</th>
                                        <th>Bank Name</th>
                                        <th>Account Type</th>
                                        <th>IFSC Code</th>
                                        <th>Account Number</th>
                                        <th>Branch Name</th>
                                        <th> Balance</th>
                                    </thead>
                                    <tbody>
                                        {accountsData
                                            .filter(item => item.type === "banks")
                                            .map(item => item.content)
                                            .flat()
                                            .map((bank, index) => (
                                                <tr key={index}>
                                                    <td>{bank.bankid}</td>
                                                    <td>{bank.bank}</td>
                                                    <td>{bank.acc_type}</td>
                                                    <td>{bank.ifsc_code}</td>
                                                    <td>{bank.account_number}</td>
                                                    <td>{bank.branch_name}</td>
                                                    <td>{bank.balance}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </>
                        </div>
                        <div className={"tab-panel" + (tabIndex === 4 ? " active" : "")}>
                            <table cellPadding={0} cellSpacing={0} className='border even_odd'>
                                <thead>
                                    <th>Partner Name</th>
                                    <th>Employee code</th>
                                </thead>
                                <tbody>
                                    {accountsData
                                        .filter(item => item.type === "partners")
                                        .map(item => item.content)
                                        .flat()
                                        .map((bank, index) => (
                                            <tr key={index}>
                                                <td>{bank.name}</td>
                                                <td>{bank.emp_id}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={"tab-panel" + (tabIndex === 5 ? " active" : "")}>
                            <>
                                <table cellPadding={0} cellSpacing={0} className='border even_odd'>
                                    <thead>
                                        <th>Bank Name</th>
                                        <th>IFSC Code</th>
                                        <th>Loan Amount</th>
                                        <th>Intrest Rate</th>
                                    </thead>
                                    <tbody>
                                        {
                                            accountsData.filter(item => item.type === "loans").map(item => item.content)[0].bank_loans.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{item.bank_name}</td>
                                                    <td>{item.ifsc_code}</td>
                                                    <td>{item.loan_amount}</td>
                                                    <td>{item.intrest_rate}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                <table className='border even_odd'>
                                    <thead>
                                        <th>Partner</th>
                                        <th>Amount</th>
                                    </thead>
                                    <tbody>
                                        {
                                            accountsData
                                                .filter(item => item.type === "loans") // Filter by type 'loans'
                                                .map(item => item.content)[0]?.partner_loans?.map((loan, index) =>
                                                    Object.entries(loan).map(([key, value]) => (
                                                        <tr key={`${index}-${key}`}>
                                                            <td>{key}</td>
                                                            <td>{value}</td>
                                                        </tr>
                                                    ))
                                                )
                                        }
                                    </tbody>
                                </table>
                            </>
                        </div>
                    </div>
                </div>}
        </div>
    );
};
const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin
});

export default connect(mapStateToProps, { getVendors })(Financials);