import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'

const PreBuilts = ({ products: { presetData }, user: { userDetails } }) => {
    const [prebuilds, setPrebuilds] = useState([])
    const [editIndex, setEditIndex] = useState(-1)
    const [dropDownIndex, setDropDownIndex] = useState(-1)
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        axios.get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/gettingprebuilts').then(res => {
            setPrebuilds(res.data)
        })
    }, [])


    const compOptions = (type) => {
        const tempArray = []
        presetData !== null && presetData.filter(ps => ps.type === type)[0]["list"].map(item => {
            return (
                tempArray.push({ value: item.presetid, label: item.kuro_title })
            )
        })
        return tempArray
    }


    const changepresets = (e, key, index) => {
        let tempprod = prebuilds
        tempprod[index].presets[key] = (Array.isArray(e) ? e.map(ps => ps.value) : [])
        setPrebuilds(tempprod)

    }

    const submitHandler = (index) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/prebuilds?type=true', prebuilds[index], config)
            .then((res) => {
                setPrebuilds(res.data)
                setEditIndex(-1)
            })
    }

    return (
        <div>
            <div className="tabs">
                <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Active</button>
                <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Inactive</button>
                <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>All</button>
            </div>
            {tabIndex === 0 && <table className="border even_odd">
                <thead>
                    <th>Build Title</th>
                    <th>Edit</th>
                </thead>
                <tbody>
                    {
                        prebuilds.length > 0 && presetData !== null && prebuilds.filter(ps => ps.active === true).map((prebuild, index) => {
                            return (
                                <tr>
                                    <td style={{ position: 'relative' }}>
                                        <table>
                                            <thead>
                                                {prebuild.title}
                                                {dropDownIndex === index ? (<button className="arrow" onClick={() => setDropDownIndex(-1)}>
                                                    ▲
                                                </button>) : (
                                                    <button className="arrow" onClick={() => setDropDownIndex(index)}>
                                                        ▼
                                                    </button>
                                                )}
                                            </thead>
                                            {
                                                dropDownIndex === index && <tbody>
                                                    <td style={{ border: "none" }}>
                                                        {editIndex === index ? <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Processor</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("cpu")} isMulti onChange={(e) => changepresets(e, "cpu", index)} defaultValue={(prebuild.presets.cpu && presetData !== null) && prebuild.presets.cpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Power Supply Unit</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("psu")} isMulti onChange={(e) => changepresets(e, "psu", index)} defaultValue={(prebuild.presets.psu && presetData !== null) && prebuild.presets.psu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Case</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("tower")} isMulti onChange={(e) => changepresets(e, "tower", index)} defaultValue={(prebuild.presets.tower && presetData !== null) && prebuild.presets.tower.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Motherboard</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("mob")} isMulti onChange={(e) => changepresets(e, "mob", index)} defaultValue={(prebuild.presets.mob && presetData !== null) && prebuild.presets.mob.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>SSD</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("ssd")} isMulti onChange={(e) => changepresets(e, "ssd", index)} defaultValue={(prebuild.presets.ssd && presetData !== null) && prebuild.presets.ssd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Fans</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("fans")} isMulti onChange={(e) => changepresets(e, "fans", index)} defaultValue={(prebuild.presets.fans && presetData !== null) && prebuild.presets.fans.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Graphics Card</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("gpu")} isMulti onChange={(e) => changepresets(e, "gpu", index)} defaultValue={(prebuild.presets.gpu && presetData !== null) && prebuild.presets.gpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>HDD</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("hdd")} isMulti onChange={(e) => changepresets(e, "hdd", index)} defaultValue={(prebuild.presets.hdd && presetData !== null) && prebuild.presets.hdd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>WiFi</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("wifi")} isMulti onChange={(e) => changepresets(e, "wifi", index)} defaultValue={(prebuild.presets.wifi && presetData !== null) && prebuild.presets.wifi.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RAM</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("ram")} isMulti onChange={(e) => changepresets(e, "ram", index)} defaultValue={(prebuild.presets.ram && presetData !== null) && prebuild.presets.ram.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Cooler</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("cooler")} isMulti onChange={(e) => changepresets(e, "cooler", index)} defaultValue={(prebuild.presets.cooler && presetData !== null) && prebuild.presets.cooler.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Operating System</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("os")} isMulti onChange={(e) => changepresets(e, "os", index)} defaultValue={(prebuild.presets.os && presetData !== null) && prebuild.presets.os.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Build Fees</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("build_fees")} isMulti onChange={(e) => changepresets(e, "build_fees", index)} defaultValue={(prebuild.presets.build_fees && presetData !== null) && prebuild.presets.build_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Shipping Fees</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("shp_fees")} isMulti onChange={(e) => changepresets(e, "shp_fees", index)} defaultValue={(prebuild.presets.shp_fees && presetData !== null) && prebuild.presets.shp_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Warranty</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("warranty")} isMulti onChange={(e) => changepresets(e, "warranty", index)} defaultValue={(prebuild.presets.warranty && presetData !== null) && prebuild.presets.warranty.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table> : <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Processor</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.cpu.length > 0 && prebuild.presets.cpu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Power Supply Unit</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.psu.length > 0 && prebuild.presets.psu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Case</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.tower.length > 0 && prebuild.presets.tower.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Motherboard</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.mob.length > 0 && prebuild.presets.mob.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>SSD</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.ssd.length > 0 && prebuild.presets.ssd.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Fans</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.fans.length > 0 && prebuild.presets.fans.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Graphics Card</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.gpu.length > 0 && prebuild.presets.gpu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>HDD</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.hdd.length > 0 && prebuild.presets.hdd.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>WiFi</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.wifi.length > 0 && prebuild.presets.wifi.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RAM</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.ram.length > 0 && prebuild.presets.ram.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Cooler</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.cooler.length > 0 && prebuild.presets.cooler.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Operating System</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.os.length > 0 && prebuild.presets.os.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Build Fees</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.build_fees.length > 0 && prebuild.presets.build_fees.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Shipping Fees</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.shp_fees.length > 0 && prebuild.presets.shp_fees.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Warranty</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.warranty.length > 0 && prebuild.presets.warranty.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>}

                                                    </td>
                                                </tbody>
                                            }
                                        </table>
                                    </td>
                                    <td>{editIndex === index ? <button onClick={() => submitHandler(index)}>Save</button> : <button onClick={() => {
                                        setEditIndex(index)
                                        setDropDownIndex(index)
                                    }}>Edit</button>}
                                        {editIndex === index && <button style={{ marginTop: "10px" }} onClick={() => { setEditIndex(-1) }}>Cancel</button>}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>}
            {tabIndex === 1 && <table className="border even_odd">
                <thead>
                    <th>Build Title</th>
                    <th>Edit</th>
                </thead>
                <tbody>
                    {
                        prebuilds.length > 0 && presetData !== null && prebuilds.filter(ps => ps.active === false).map((prebuild, index) => {
                            return (
                                <tr>
                                    <td style={{ position: 'relative' }}>
                                        <table>
                                            <thead>
                                                {prebuild.title}
                                                {dropDownIndex === index ? (<button className="arrow" onClick={() => setDropDownIndex(-1)}>
                                                    ▲
                                                </button>) : (
                                                    <button className="arrow" onClick={() => setDropDownIndex(index)}>
                                                        ▼
                                                    </button>
                                                )}
                                            </thead>
                                            {
                                                dropDownIndex === index && <tbody>
                                                    <td style={{ border: "none" }}>
                                                        {editIndex === index ? <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Processor</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("cpu")} isMulti onChange={(e) => changepresets(e, "cpu", index)} defaultValue={(prebuild.presets.cpu && presetData !== null) && prebuild.presets.cpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Power Supply Unit</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("psu")} isMulti onChange={(e) => changepresets(e, "psu", index)} defaultValue={(prebuild.presets.psu && presetData !== null) && prebuild.presets.psu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Case</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("tower")} isMulti onChange={(e) => changepresets(e, "tower", index)} defaultValue={(prebuild.presets.tower && presetData !== null) && prebuild.presets.tower.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Motherboard</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("mob")} isMulti onChange={(e) => changepresets(e, "mob", index)} defaultValue={(prebuild.presets.mob && presetData !== null) && prebuild.presets.mob.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>SSD</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("ssd")} isMulti onChange={(e) => changepresets(e, "ssd", index)} defaultValue={(prebuild.presets.ssd && presetData !== null) && prebuild.presets.ssd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Fans</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("fans")} isMulti onChange={(e) => changepresets(e, "fans", index)} defaultValue={(prebuild.presets.fans && presetData !== null) && prebuild.presets.fans.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Graphics Card</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("gpu")} isMulti onChange={(e) => changepresets(e, "gpu", index)} defaultValue={(prebuild.presets.gpu && presetData !== null) && prebuild.presets.gpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>HDD</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("hdd")} isMulti onChange={(e) => changepresets(e, "hdd", index)} defaultValue={(prebuild.presets.hdd && presetData !== null) && prebuild.presets.hdd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>WiFi</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("wifi")} isMulti onChange={(e) => changepresets(e, "wifi", index)} defaultValue={(prebuild.presets.wifi && presetData !== null) && prebuild.presets.wifi.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RAM</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("ram")} isMulti onChange={(e) => changepresets(e, "ram", index)} defaultValue={(prebuild.presets.ram && presetData !== null) && prebuild.presets.ram.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Cooler</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("cooler")} isMulti onChange={(e) => changepresets(e, "cooler", index)} defaultValue={(prebuild.presets.cooler && presetData !== null) && prebuild.presets.cooler.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Operating System</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("os")} isMulti onChange={(e) => changepresets(e, "os", index)} defaultValue={(prebuild.presets.os && presetData !== null) && prebuild.presets.os.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Build Fees</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("build_fees")} isMulti onChange={(e) => changepresets(e, "build_fees", index)} defaultValue={(prebuild.presets.build_fees && presetData !== null) && prebuild.presets.build_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Shipping Fees</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("shp_fees")} isMulti onChange={(e) => changepresets(e, "shp_fees", index)} defaultValue={(prebuild.presets.shp_fees && presetData !== null) && prebuild.presets.shp_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Warranty</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("warranty")} isMulti onChange={(e) => changepresets(e, "warranty", index)} defaultValue={(prebuild.presets.warranty && presetData !== null) && prebuild.presets.warranty.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table> : <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Processor</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.cpu.length > 0 && prebuild.presets.cpu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Power Supply Unit</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.psu.length > 0 && prebuild.presets.psu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Case</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.tower.length > 0 && prebuild.presets.tower.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Motherboard</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.mob.length > 0 && prebuild.presets.mob.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>SSD</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.ssd.length > 0 && prebuild.presets.ssd.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Fans</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.fans.length > 0 && prebuild.presets.fans.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Graphics Card</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.gpu.length > 0 && prebuild.presets.gpu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>HDD</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.hdd.length > 0 && prebuild.presets.hdd.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>WiFi</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.wifi.length > 0 && prebuild.presets.wifi.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RAM</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.ram.length > 0 && prebuild.presets.ram.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Cooler</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.cooler.length > 0 && prebuild.presets.cooler.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Operating System</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.os.length > 0 && prebuild.presets.os.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Build Fees</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.build_fees.length > 0 && prebuild.presets.build_fees.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Shipping Fees</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.shp_fees.length > 0 && prebuild.presets.shp_fees.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Warranty</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.warranty.length > 0 && prebuild.presets.warranty.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>}

                                                    </td>
                                                </tbody>
                                            }
                                        </table>
                                    </td>
                                    <td>{editIndex === index ? <button onClick={() => submitHandler(index)}>Save</button> : <button onClick={() => {
                                        setEditIndex(index)
                                        setDropDownIndex(index)
                                    }}>Edit</button>}
                                        {editIndex === index && <button style={{ marginTop: "10px" }} onClick={() => { setEditIndex(-1) }}>Cancel</button>}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>}

            {tabIndex === 2 && <table className="border even_odd">
                <thead>
                    <th>Build Title</th>
                    <th>Edit</th>
                </thead>
                <tbody>
                    {
                        prebuilds.length > 0 && presetData !== null && prebuilds.map((prebuild, index) => {
                            return (
                                <tr>
                                    <td style={{ position: 'relative' }}>
                                        <table>
                                            <thead>
                                                {prebuild.title}
                                                {dropDownIndex === index ? (<button className="arrow" onClick={() => setDropDownIndex(-1)}>
                                                    ▲
                                                </button>) : (
                                                    <button className="arrow" onClick={() => setDropDownIndex(index)}>
                                                        ▼
                                                    </button>
                                                )}
                                            </thead>
                                            {
                                                dropDownIndex === index && <tbody>
                                                    <td style={{ border: "none" }}>
                                                        {editIndex === index ? <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Processor</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("cpu")} isMulti onChange={(e) => changepresets(e, "cpu", index)} defaultValue={(prebuild.presets.cpu && presetData !== null) && prebuild.presets.cpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Power Supply Unit</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("psu")} isMulti onChange={(e) => changepresets(e, "psu", index)} defaultValue={(prebuild.presets.psu && presetData !== null) && prebuild.presets.psu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Case</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("tower")} isMulti onChange={(e) => changepresets(e, "tower", index)} defaultValue={(prebuild.presets.tower && presetData !== null) && prebuild.presets.tower.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Motherboard</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("mob")} isMulti onChange={(e) => changepresets(e, "mob", index)} defaultValue={(prebuild.presets.mob && presetData !== null) && prebuild.presets.mob.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>SSD</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("ssd")} isMulti onChange={(e) => changepresets(e, "ssd", index)} defaultValue={(prebuild.presets.ssd && presetData !== null) && prebuild.presets.ssd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Fans</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("fans")} isMulti onChange={(e) => changepresets(e, "fans", index)} defaultValue={(prebuild.presets.fans && presetData !== null) && prebuild.presets.fans.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Graphics Card</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("gpu")} isMulti onChange={(e) => changepresets(e, "gpu", index)} defaultValue={(prebuild.presets.gpu && presetData !== null) && prebuild.presets.gpu.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>HDD</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("hdd")} isMulti onChange={(e) => changepresets(e, "hdd", index)} defaultValue={(prebuild.presets.hdd && presetData !== null) && prebuild.presets.hdd.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>WiFi</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("wifi")} isMulti onChange={(e) => changepresets(e, "wifi", index)} defaultValue={(prebuild.presets.wifi && presetData !== null) && prebuild.presets.wifi.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RAM</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("ram")} isMulti onChange={(e) => changepresets(e, "ram", index)} defaultValue={(prebuild.presets.ram && presetData !== null) && prebuild.presets.ram.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Cooler</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("cooler")} isMulti onChange={(e) => changepresets(e, "cooler", index)} defaultValue={(prebuild.presets.cooler && presetData !== null) && prebuild.presets.cooler.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Operating System</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("os")} isMulti onChange={(e) => changepresets(e, "os", index)} defaultValue={(prebuild.presets.os && presetData !== null) && prebuild.presets.os.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Build Fees</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("build_fees")} isMulti onChange={(e) => changepresets(e, "build_fees", index)} defaultValue={(prebuild.presets.build_fees && presetData !== null) && prebuild.presets.build_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Shipping Fees</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("shp_fees")} isMulti onChange={(e) => changepresets(e, "shp_fees", index)} defaultValue={(prebuild.presets.shp_fees && presetData !== null) && prebuild.presets.shp_fees.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                    <td>Warranty</td>
                                                                    <td colSpan="2">
                                                                        <Select classNamePrefix="kuro-search-select" options={compOptions("warranty")} isMulti onChange={(e) => changepresets(e, "warranty", index)} defaultValue={(prebuild.presets.warranty && presetData !== null) && prebuild.presets.warranty.map(ps => { return ({ value: ps, label: presetData.filter(item => item.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title }) })} placeholder="Select Component" className="react-select-container" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table> : <table style={{ margin: 0 }} className="border even_odd" cellPadding="0" cellSpacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Processor</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.cpu.length > 0 && prebuild.presets.cpu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "cpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Power Supply Unit</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.psu.length > 0 && prebuild.presets.psu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "psu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Case</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.tower.length > 0 && prebuild.presets.tower.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "tower")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Motherboard</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.mob.length > 0 && prebuild.presets.mob.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "mob")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>SSD</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.ssd.length > 0 && prebuild.presets.ssd.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "ssd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Fans</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.fans.length > 0 && prebuild.presets.fans.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "fans")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Graphics Card</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.gpu.length > 0 && prebuild.presets.gpu.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "gpu")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>HDD</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.hdd.length > 0 && prebuild.presets.hdd.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "hdd")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>WiFi</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.wifi.length > 0 && prebuild.presets.wifi.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "wifi")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RAM</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.ram.length > 0 && prebuild.presets.ram.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "ram")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Cooler</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.cooler.length > 0 && prebuild.presets.cooler.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "cooler")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Operating System</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.os.length > 0 && prebuild.presets.os.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "os")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Build Fees</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.build_fees.length > 0 && prebuild.presets.build_fees.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "build_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Shipping Fees</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.shp_fees.length > 0 && prebuild.presets.shp_fees.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "shp_fees")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                    <td>Warranty</td>
                                                                    <td colSpan="2">
                                                                        {presetData !== null && prebuild.presets.warranty.length > 0 && prebuild.presets.warranty.map(ps => { return (<>{presetData.filter(psgrp => psgrp.type === "warranty")[0].list.filter(item => item.presetid === ps)[0].kuro_title}<br /></>) })}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>}

                                                    </td>
                                                </tbody>
                                            }
                                        </table>
                                    </td>
                                    <td>{editIndex === index ? <button onClick={() => submitHandler(index)}>Save</button> : <button onClick={() => {
                                        setEditIndex(index)
                                        setDropDownIndex(index)
                                    }}>Edit</button>}
                                        {editIndex === index && <button style={{ marginTop: "10px" }} onClick={() => { setEditIndex(-1) }}>Cancel</button>}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        products: state.products
    }
}
export default connect(mapStateToProps)(PreBuilts)