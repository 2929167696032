import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { getVendors } from '../actions/admin'
import DeleteBox from '../components/DeleteBox'
import { useHistory } from 'react-router-dom'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')

const PurchaseOrder = ({ match, user: { token, userDetails }, admin: { vendors }, getVendors }) => {

    let history = useHistory();
    const { params: { po_no } } = match
    const [po, setpo] = useState(null)
    const [newpo, setnewpo] = useState(null)
    const [editflag, seteditflag] = useState(false)
    const [loaddeletebox, setloaddeletebox] = useState(false)
    const [deleteid, setdeleteid] = useState("")
    const access = userDetails.accesslevel.purchase_orders
    useEffect(() => {
        if (access === "NA") {
            history.push("/unauthorized");
            return;
        }
    }
        , [])
    useEffect(() => {
        getVendors()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/purchaseorders?po_no=' + po_no, config).then(res => {
            setpo(res.data[0])
            setnewpo(res.data[0])
        }).catch((err)=>{
            if(err.response.status===401){
                history.push("/unauthorized")
            }
        })
    }, [po_no, getVendors])

    const editpo = () => {
        seteditflag(true)
    }

    const updatepo = (e, key, num = false, index) => {
        let temppo = { ...newpo }
        if (key === "vendor") {
            let vendor = e.target.value.split('+')
            temppo[key] = vendor[0]
            temppo["gstin"] = vendor[1]
        }
        else if (key === "products") {
            temppo[key][index] = e
        }
        else {
            temppo[key] = (num === true) ? Number(e.target.value) : e.target.value
        }
        setnewpo(temppo)
    }

    const updatenarration = (e, index) => {
        let temppo = { ...newpo }
        temppo["narration"][index]["desc"] = e.target.value
        setnewpo(temppo)
    }

    const addrow = () => {
        let temppo = { ...newpo }
        temppo["narration"].push({ 'desc': '', 'narration_date': '', 'narration_by': '' })
        setnewpo(temppo)
    }

    const removerow = (id) => {
        let temppo = { ...newpo }
        delete temppo.narration[id]
        setnewpo(temppo)
    }

    const submitpo = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/purchaseorders?update=update&po_no=' + newpo["po_no"], newpo, config)
        setpo(newpo)
        seteditflag(false)
    }

    const cancelupdates = () => {
        setnewpo(po)
        seteditflag(false)
    }

    const deleteHandler = (po_no) => {
        setloaddeletebox(true)
        setdeleteid(po_no)
    }

    const deletepo = (data) => {
        setloaddeletebox(false)
        setdeleteid("")
        history.push('/purchase-orders')

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.delete(process.env.REACT_APP_KC_API_URL + 'kuroadmin/purchaseorders?po_no=' + data.po_no, config)
    }

    const cancelHandler = () => {
        setloaddeletebox(false)
        setdeleteid("")
    }

    return (
        <div className="po txt-light">
            {editflag ? (
                [newpo !== null &&
                    <div className="edit-po">
                        <table className='border' cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th>PO Number sdfsdfaf</th>
                                    <td>{newpo.po_no}</td>
                                </tr>
                                <tr>
                                    <th>type</th>
                                    <td>
                                        {po.type}
                                    </td>
                                 </tr>
                                 <tr>
                                    <th>tags</th>
                                    <td>{po.tags}</td>
                                </tr>
                                <tr>
                                    <th>Vendor</th>
                                    <td>{newpo.vendor}</td>
                                
                                </tr>
                                {newpo.products && newpo.products.length > 0 && <tr>
                                    <th>Products</th>
                                    {newpo.products.map((item, i) =>
                                        <td key={i}>
                                            <input type="text" readOnly value={item.title} onChange={(e) => updatepo(e, "products", false, i)} placeholder="Product" />
                                        </td>
                                    )}
                                </tr>}
                                <tr>
                                    <th>PO Total Amount</th>
                                    <td>
                                        <input type="number" value={newpo.total_amount} onChange={(e) => updatepo(e, "total_amount", true)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>PO Narration</th>
                                    <td>
                                        {newpo.narration.length > 0 ? (
                                            newpo.narration.map((item, i) =>
                                                <div key={i}>
                                                    <textarea value={item.desc} onChange={(e) => updatenarration(e, i)} />
                                                    <span className="prod-span" onClick={() => removerow(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                    {i === newpo.narration.length - 1 && <span className="prod-span" onClick={() => addrow()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>}
                                                </div>
                                            )
                                        ) : (
                                            <span className="prod-span" onClick={() => addrow()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <ul className="btns">
                            <li><button onClick={submitpo}>Submit</button></li>
                            <li><button onClick={cancelupdates}>Cancel</button></li>
                        </ul>
                    </div>
                ]
            ) : (
                <div className="po">
                    {access==="edit" && <ul className="btns">
                        <li><button onClick={editpo}>Edit the PO Details</button></li>
                    </ul>}
                    
                    <p>&nbsp;</p>
                    <table cellPadding="0" cellSpacing="0" className="border">
                        {po !== null &&
                            <tbody>
                                <tr>
                                    <th>PO Number</th>
                                    <td>{po.po_no}</td>
                                </tr>
                                <tr>
                                    <th>type</th>
                                    <td>
                                        {po.type}
                                    </td>
                                </tr>    
                                <tr>
                                    <th>tags</th>
                                    <td>{po.tags}</td>
                                </tr>
                                <tr>        
                                    <th>Vendor</th>
                                    <td>{newpo!==null && newpo.vendor}</td>
                                </tr>
                                { newpo!==null && newpo.products && newpo.products.length > 0 && <tr>
                                    <th>Products</th>
                                    {newpo.products.map((item, i) =>
                                        <tr>
                                            <td key={i}> {item.title} </td>
                                        </tr>
                                    )}
                                </tr>}
                                <tr>
                                    <th>PO Total Amount</th>
                                    <td>{CurrencyFormat(po.total_amount)}</td>
                                </tr>
                                <tr>
                                    <th>PO Narration</th>
                                    <td>
                                        {po.narration.map((item, j) =>
                                            <div key={j}>
                                                {item.desc} - {item.narration_date === "" ? "" : moment(item.narration_date).format('DD-MM-YYYY')} - {item.narration_by}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </table>
                    <p>&nbsp;</p>
                    <ul className='btns'>
                        {userDetails.access==="Super" && <li><button onClick={(e) => deleteHandler(po.po_no)}>Delete PO</button> </li> }
                        
                        <li><KuroLink to="/purchase-orders"><button>Back</button></KuroLink></li>
                    </ul>
                </div>
            )}
            {loaddeletebox && <DeleteBox msg="Are you sure you want to delete the PO?" okhandler={deletepo} cancelhandler={cancelHandler} data={{ "po_no": deleteid }} />}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(PurchaseOrder)