import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { getVendors } from '../actions/admin'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import '../styles/tabs.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

moment.tz.setDefault('Asia/Kolkata')

const PurchaseOrders = ({ user: {token, userDetails}, admin: { vendors }, getVendors }) => {

    const [podata, setpodata] = useState(null)
    const [fpodata, setfpodata] = useState(null)
    const [searchtxt, setsearchtxt] = useState("")
    const [limit, setlimit] = useState(30)
    const history = useHistory()
    const access = userDetails.accesslevel.purchase_orders

    useEffect(()=>{
        if(access==="NA"){
            history.push("/unauthorized")
            return;
        }
    },[])

    useEffect(() => {
        getVendors()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/purchaseorders?limit=' + limit, config).then(res => {
            setpodata(res.data)
            setfpodata(res.data)
            if (res.data.length < limit) {
                setlimit(0)
            }
        }).catch((err)=>{
            if(err.response.status===401){
                history.push("/unauthorized")
            }
        })
    }, [limit])

    const limitHandler = (all) =>{
        if (all) {
            setlimit(0)
        } else {
            setlimit(limit + 30)
        }
    }

    useEffect(() => {
        if (searchtxt === "") {
            setfpodata(podata)
        } else {
            let temppodata = []
            let fltvendors = vendors.filter(ven => ven.name.toLowerCase().includes(searchtxt.toLowerCase()))
            for (let po of podata) {
                if (fltvendors.filter(ven => ven.vendor_code === po.vendor).length > 0) {
                    temppodata.push(po)
                }
            }
            setfpodata(temppodata)
        }
    }, [searchtxt])

    return (
        <div className="txt-light-grey">
        {vendors == null ? (
           <h1>Loading...</h1>
        ) : (
        <>
            <h2 className="txt-light-grey">Purchase Orders</h2>
            {
                (access==="write" || access==="edit") &&
            <ul className='btns'>
                <li><KuroLink to="/create-po"><button>Create PO</button></KuroLink></li>
            </ul>
            }
            <div className='search_container'>
                <input type='text' name='search' onChange = {(e) => setsearchtxt(e.target.value)} placeholder="Search...."/>
            </div>
            <table className="border even_odd" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <th>PO Number</th>
                        <th>Vendor</th>
                        <th>Type</th>
                        <th>Total Amount</th>
                        <th>PO Narration</th>
                    </tr>
                {fpodata !== null && fpodata.map((po, i) =>
                    <tr key={i}>
                        <td><KuroLink to={'purchase-orders/' + po.po_no}>{po.po_no}</KuroLink></td>
                        <td>{po.vendor !== "" && vendors.filter(item => item.vendor_code === po.vendor)[0].name}</td>
                        <td>{po.type}</td>
                        <td>{CurrencyFormat(po.total_amount)}</td>
                        <td>
                        {po.narration.map((item, j) =>
                            <p key={j}>
                                {item.desc} - {item.narration_by} - {item.narration_date}
                            </p>
                        )}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            {limit !== 0 && searchtxt === "" && fpodata !== null &&
            <ul className='btns'>
                <li><button className='btn' onClick={() => limitHandler(false)}>Show More</button></li>
                <li><button className='btn' onClick={() => limitHandler(true)}>Show All</button></li>
            </ul>
            }
            <p>&nbsp;</p>
        </>
        )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(PurchaseOrders)