import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import KuroLink from '../components/common/KuroLink'
import ResponseBox from '../components/ResponseBox'
import { getVendors } from '../actions/admin'
import note from '../assets/img/button.png'
import '../styles/table.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const CreateInwardDNote = ({user: {token, userDetails}, admin: { vendors }, getVendors }) => {

    const defdnote = {'vendor': '', 'gstin': '', 'debitnote_no': '', 'debitnote_date': '', 'totalprice': 0, 'cgst': 0, 'sgst': 0, 'igst': 0, 'pay_status': 'Payment Pending', 'settled': 'No'}
    const access=userDetails.accesslevel.inward_debitnotes;
    const [dndata, setdndata] = useState(defdnote)
    const [submitted, setsubmitted] = useState(false)
    const [resstatus, setresstatus] = useState("")
const history=useHistory()
    useEffect(() => {
        if(access==="NA"|| access==="read"){
            history.push("/unauthorized")
            return;
        }
        getVendors()
    }, [])

    const updatedebitnote = (key, e) => {
        let tempdebitnote = {...dndata}
        tempdebitnote[key] = e.target.value
        if (key === "gstin") {
            tempdebitnote.cgst = 0
            tempdebitnote.sgst = 0
            tempdebitnote.igst = 0
            tempdebitnote.totalprice = 0
        }
        setdndata(tempdebitnote)
    }

    const updatedebitnotedate = (date) => {
        let tempdebitnote = {...dndata}
        tempdebitnote["debitnote_date"] = date
        setdndata(tempdebitnote)
    }

    const round = (type, value, decimals = 0, even=false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateprice = (key, e) => {
        let tempdebitnote = {...dndata}
        tempdebitnote[key] = e.target.value
        let reg = /^[0-9.]*$/
        if (!reg.test(tempdebitnote[key])) {
            return tempdebitnote[key]
        }
        if (key === "totalprice") {
            if (tempdebitnote.gstin === "") {
                tempdebitnote.cgst = 0
                tempdebitnote.sgst = 0
                tempdebitnote.igst = 0
            } else if (tempdebitnote.gstin.startsWith("36")) {
                tempdebitnote.cgst = round("round", (parseFloat(tempdebitnote.totalprice) / 1.18) * 0.09, 2)
                tempdebitnote.sgst = round("round", (parseFloat(tempdebitnote.totalprice) / 1.18) * 0.09, 2)
                tempdebitnote.igst = 0
            } else {
                tempdebitnote.cgst = 0
                tempdebitnote.sgst = 0
                tempdebitnote.igst = round("round", (parseFloat(tempdebitnote.totalprice) / 1.18) * 0.18, 2)
            }
        } else if (key === "cgst") {
            tempdebitnote.sgst = e.target.value
        } else if (key === "igst") {
            tempdebitnote.cgst = 0
            tempdebitnote.sgst = 0
        }
        setdndata(tempdebitnote)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let debitnote = dndata

        debitnote["debitnote_no"] = debitnote["debitnote_no"].trim()

        for (let key of ["totalprice", "cgst", "sgst", "igst"]) {
            debitnote[key] = parseFloat(debitnote[key])
        }

        if (debitnote.debitnote_no !== "" && debitnote.vendor !== "" && debitnote.debitnote_date !== "" && debitnote.totalprice !== 0) {
            let debitnotes = []
            debitnotes.push(debitnote)
            axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwarddebitnotes', debitnotes, config)
            .then((res) => {
                setresstatus(res.status)
                setsubmitted(true)
            }).catch((err)=>{
                if(err.response.status===401){
                    history.push("/unauthorized")
                }
                setsubmitted(false)
            })
            
        }
    }

    const resetHandler = () => {
        setdndata(defdnote)
    }

    const okHandler =( ) => {
        setresstatus("")
        setsubmitted(false)
    }

    return (
        <div className="kuro_inventory entry txt-light">
            <h2 className="txt-light-grey">Debit Note</h2>
            <div className="note"><p>Instructions </p> 
                <img src={note} alt='instruction' className='note_img'/>:
                     <span className="note_text">lorem text</span>
                </div>
                <div>
                    <p className='txt-right'>Note:lorem text</p><br />
                </div>
            {submitted ? (
            <div>
                {resstatus === 200 ? (
                    <ResponseBox msg="Debit Note has been Submitted Succesfully." okhandler={okHandler} />
                ) : resstatus=== "" ? null : (
                    <ResponseBox msg="Oops something gone wrong!" okhandler={okHandler} />
                )}
            </div>
            ) : (
            <>
            {dndata !== null &&
            <table className="home border" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <th>Vendor</th>
                        <td>
                            <select value={dndata.vendor} onChange={(e) => updatedebitnote("vendor", e)}>
                                <option value="">Select Vendor</option>
                                {vendors !== null && 
                                    vendors.map((vendor, j) =>
                                        <option key={j} value={vendor.vendor_code}>{vendor.name}</option>
                                    )
                                }
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Vendor GSTIN</th>
                        <td>
                            <select value={dndata.gstin} onChange={(e) => updatedebitnote("gstin", e)}>
                                <option value="">Select Vendor GST</option>
                                {vendors !== null && dndata.vendor !== "" && 
                                    vendors.filter(vendor => vendor.vendor_code === dndata.vendor).map((vendor, j) =>
                                        vendor.gstdetails.map((item, k) =>
                                            <option key={j+k} value={item.gst.gstin}>{item.gst.gstin}</option>
                                        )
                                    )
                                }
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Debit Note No.</th>
                        <td>
                            <input value={dndata.debitnote_no} onChange={(e) => updatedebitnote("debitnote_no", e)} />
                        </td>
                    </tr>
                    <tr>
                        <th>Debit Note Date</th>
                        <td>
                            <DatePicker className="medium" dateFormat='dd-MMM-yyyy' selected={dndata.debitnote_date} onChange={date => updatedebitnotedate(date)} />
                        </td>
                    </tr>
                    <tr>
                        <th>Debit Note Total</th>
                        <td>
                            <input className="small" value={dndata.totalprice} onChange={(e) => updateprice("totalprice", e)} />
                        </td>
                    </tr>
                    <tr>
                        <th>CGST/SGST</th>
                        <td>
                        {dndata.gstin.startsWith("36") ? (
                            <input className="small" value={dndata.cgst} onChange={(e) => updateprice("cgst", e)} />
                        ) : (
                            dndata.cgst
                        )}
                        </td>
                    </tr>
                    <tr>
                        <th>IGST</th>
                        <td>
                        {dndata.gstin === "" || dndata.gstin.startsWith("36") ? (
                            dndata.igst
                        ) : (
                            <input className="small" value={dndata.igst} onChange={(e) => updateprice("igst", e)} />
                        )}
                        </td>
                    </tr>
                    <tr>
                        <th>Payment Status</th>
                        <td>
                            <select className="medium" value={dndata.pay_status} onClick={(e) => updatedebitnote("pay_status", e)}>
                                <option value="Payment Pending">Payment Pending</option>
                                <option value="Paid">Paid</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Settled</th>
                        <td>
                            <select value={dndata.settled} onChange={(e) => updatedebitnote("settled", e)}>
                                <option value="Yes">Yes</option>
                                <option value="No" selected>No</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            }
            <ul className='btns'>
                <li>
                    <button onClick={resetHandler}>Clear All</button>
                </li>
                <li>
                    <button onClick={submitHandler}>Submit</button>
                </li>
                <li>
                    <KuroLink to= "/inward-debitnotes"><button>Back</button></KuroLink>
                </li>
            </ul>
            </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(CreateInwardDNote)