import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ResponseBox from '../../components/ResponseBox';
import Switch from 'react-switch';
import { useHistory} from 'react-router-dom/cjs/react-router-dom.min';

const EmployeeAccessLevelUpdate = ({ user: { token, userDetails }, match }) => {
    const { params: { empid , name} } = match;
    const [empdata, setEmpData] = useState({});
    const [responseStatus, setResponseStatus] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const history = useHistory();
    const accessKeyNames = {
        inward_invoices: "Inward Invoices",
        inward_creditnotes: "Inward Creditnotes",
        inward_debitnotes: "Inward Debitnotes",
        outward_invoices: "Outward Invoices",
        outward_debitnotes: "Outward Debitnotes",
        purchase_orders: "Purchase Orders",
        counters: "Counters",
        vendors: "Vendors",
        financials: "financials",
        export_data: "Export Data",
        user_list: "User List",
        estimates: "Estimates",
        inward_payments: "Inward Payments",
        outward_payments: "Outward Payments",
        orders: " TP Orders",
        offline_orders: "Offline Orders",
        online_orders: "Online Orders",
        products: "Products",
        inventory: "Inventory",
        indent: "Indent",
        stock: "Stock",
        audit: "Audit",
        tp_builds: "Tp Builds",
        paymentvouchers: "Paymentvouchers",
        presets: "Presets",
        replace_presets: "Replace Presets",
        service_request: "Service Request",
        emp_attendance: "Emp Attendance",
        product_finder: "Product Finder",
        peripherals: "Peripherals",
        portal_editor: "Portal Editor",
        employees: "Employees",
        profile: "Employee Profile",
        employee_accesslevel: "Employee Accesslevel",
        job_application: "Job Application",
        pre_builts_finder: "Pre Builts Finder", 
        outward_creditnotes:"Outward Creditnotes",
        employees_salary : "Employees Salary"
    };

    useEffect(()=>{
        const access=userDetails.accesslevel.employee_accesslevel;
        if(access!=="edit"){
            history.push('/unauthorized')
            return;
        }
    },[])
    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        };

        axios.get(process.env.REACT_APP_KC_API_URL + 'accesslevel?empid=' + empid, config)
            .then((res) => {
                setEmpData(res.data);
                setFilteredData(Object.entries(res.data).filter(([key, value]) => value === 'NA' || value === 'read' || value === 'write' || value==="edit"));
            });

    }, [token, empid]);


    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        };
        axios.post(process.env.REACT_APP_KC_API_URL + 'accesslevel', empdata, config)
            .then((res) => {
                setResponseStatus(res.status);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
    };

    const okHandler = () => {
        setResponseStatus(null);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const filtered = Object.entries(empdata).filter(([key]) =>
            key.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredData(filtered);
    };


    const handleSwitchChange = (key, value) => {
        const newEmpData = { ...empdata };
        newEmpData[key] = value;
        setEmpData(newEmpData);
    };

    const renderSwitches = (key) => {
        return (
            <div className='display-center' style={{ gap: "20px" }}>
                <label className='display-center'>NA
                    <Switch
                        checked={empdata[key] === 'NA'}
                        onChange={() => handleSwitchChange(key, 'NA')}
                        onColor="#8cbae8"
                        onHandleColor="#1976d2"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={13}
                        width={35}
                        className="react-switch"
                        id={`${key}-na`}
                    />
                </label>
                <label className='display-center'>Read Only
                    <Switch
                        checked={empdata[key] === 'read'}
                        onChange={() => handleSwitchChange(key, 'read')}
                        onColor="#8cbae8"
                        onHandleColor="#1976d2"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={13}
                        width={35}
                        className="react-switch"
                        id={`${key}-read`}
                    />
                </label>
                <label className='display-center'>Read/Write
                    <Switch
                        checked={empdata[key] === 'write'}
                        onChange={() => handleSwitchChange(key, 'write')}
                        onColor="#8cbae8"
                        onHandleColor="#1976d2"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={13}
                        width={35}
                        className="react-switch"
                        id={`${key}-write`}
                    /></label>
                <label className='display-center'>Edit
                    <Switch
                        checked={empdata[key] === 'edit'}
                        onChange={() => handleSwitchChange(key, 'edit')}
                        onColor="#8cbae8"
                        onHandleColor="#1976d2"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={13}
                        width={35}
                        className="react-switch"
                        id={`${key}-edit`}
                    />
                </label>
            </div>
        );
    };

    return (
        <div className='employee txt-light-grey'>
            <h2>Employee Access Level Update</h2>
            <button style={{ width: '70px', marginBottom: '20px' }} onClick={() => history.push("/employee-accesslevel")}> Go Back</button>
            <input
                type="text"
                placeholder="Search Access Keys"
                value={searchTerm}
                onChange={handleSearch}
                style={{ marginBottom: '20px', padding: '5px', maxWidth: "400px" }}
            />

            <table className='border'>
                <tbody>
                    <tr>
                        <th>Employee Name</th>
                        <td>{name}</td>
                    </tr>
                    {filteredData.length > 0 ? (filteredData.map(([key, value]) => (
                        accessKeyNames[key] &&
                        <tr key={key}>
                          <th>{accessKeyNames[key]}</th>
                            <td>
                                {renderSwitches(key)}
                            </td>
                        </tr>
                    ))) : (<td colSpan={2}>Not found</td>)}
                </tbody>
            </table>

            <ul className='btns'>
                <li><button onClick={submitHandler}>Submit</button></li>
            </ul>
            {responseStatus === 200 && <ResponseBox msg={"Access Level Updated Successfully"} okhandler={okHandler} />}
            {responseStatus && responseStatus !== 200 && <ResponseBox msg={"Ups something went wrong"} okhandler={okHandler} />}
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(EmployeeAccessLevelUpdate);
