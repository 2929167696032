import axios from 'axios';
import { UPDATE_COMPONENTS, UPDATE_MONITORS, UPDATE_ACCESSORIES, UPDATE_NETWORKING, UPDATE_EXTERNAL, UPDATE_PRESETS, UPDATE_PRODUCTS } from './types';

// Get the Components
export const getComponents = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/productdata?collection=components')
        .then((res) => {
            dispatch({
                type: UPDATE_COMPONENTS,
                payload: res.data,
            });
        })
        .catch((err) => {
        });
};

export const getProducts = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/storedata')
        .then((res) => {
            dispatch({
                type: UPDATE_PRODUCTS,
                payload: res.data,
            });
        })
        .catch((err) => {
        });

};


// Get the Monitors
export const getMonitors = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/productdata?collection=monitors')
        .then((res) => {
            dispatch({
                type: UPDATE_MONITORS,
                payload: res.data,
            });
        })
        .catch((err) => {
        });
};

// Get the Accessories
export const getAccessories = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/productdata?collection=accessories')
        .then((res) => {
            dispatch({
                type: UPDATE_ACCESSORIES,
                payload: res.data,
            });
        })
        .catch((err) => {
        });
};

// Get the Accessories
export const getNetworking = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/productdata?collection=networking')
        .then((res) => {
            dispatch({
                type: UPDATE_NETWORKING,
                payload: res.data,
            });
        })
        .catch((err) => {
        });
};

// Get the External Devices
export const getExternal = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/productdata?collection=external')
        .then((res) => {
            dispatch({
                type: UPDATE_EXTERNAL,
                payload: res.data,
            });
        })
        .catch((err) => {
        });
};

// Get the Presets
export const getPresets = () => (dispatch) => {
    axios
        .get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets')
        .then((res) => {
            dispatch({
                type: UPDATE_PRESETS,
                payload: res.data,
            });
        })
        .catch((err) => {
        });
};