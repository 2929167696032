import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { utils, writeFile } from 'xlsx'
import { useHistory } from "react-router-dom"

const Exportdata = ({ user: { token, userDetails } }) => {

    const [datatype, setdatatype] = useState("")
    const [duration, setduration] = useState("")
    const access = userDetails.accesslevel.export_data;
    const history = useHistory()
    useEffect(() => {
        if (access === "NA") {
            history.push('/unauthorized')
            return;
        }
    })
    const updatedatatype = (e) => {
        setdatatype(e.target.value)
    }

    const updateduration = (e) => {
        setduration(e.target.value)
    }

    const exportData = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let file_name = " - Kuro Cadence LLP.xlsx"

        if (datatype === "exportinwardinvoices") {
            file_name = 'Inward Invoices' + file_name
        } else if (datatype === "exportoutwardinvoices") {
            file_name = 'Sales Invoices' + file_name
        } else if (datatype === "exportinwardpayments") {
            file_name = 'Inward Payments' + file_name
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/' + datatype + '?duration=' + duration, config)
            .then((res) => {
                const wb = utils.book_new()
                for (let wsheet of res.data) {
                    const ws = utils.json_to_sheet(wsheet.details)
                    /* create workbook and append worksheet */
                    utils.book_append_sheet(wb, ws, wsheet.sheetName)
                }
                /* export to XLSX */
                writeFile(wb, file_name)
            })
    }

    return (
        <div className='txt-light-grey'>
            <h3>Export Data</h3>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <select value={datatype} onChange={(e) => updatedatatype(e)}>
                                    <option value="">Select Data Type &nbsp; &nbsp;&nbsp;</option>
                                    <option value="exportinwardinvoices">Inward Data</option>
                                    <option value="exportoutwardinvoices">Outward Data</option>
                                    <option value="exportinwardpayments">Inward Payments Data</option>
                                </select>
                            </td>
                            <td>
                                <select value={duration} onChange={(e) => updateduration(e)}>
                                    <option value="">Select Duration</option>
                                    <option value="curr_month">Current Month</option>
                                    <option value="last_month">Last Month</option>
                                    <option value="curr_fy">Current Financial Year</option>
                                    <option value="last_fy">Last Financial Year</option>
                                </select>
                            </td>
                            <td>
                                <button onClick={exportData}>Export Data</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Exportdata)